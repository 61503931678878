import React, { useCallback, useEffect, useState } from 'react';
import * as api from '../../../api/api';
import Number from '../../../components/center/statistics/Number';
import Graph from '../../../components/center/Graph';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';

export default function Interest() {
    const [startDate, setStartDate] = useState('2010-01-01');
    const [endDate, setEndDate] = useState(()=>{
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        return `${year}-${month < 10 ? 0 : ''}${month}-${date}`
    });
    const [list, setList] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState();

    const apiStat = useCallback(()=>{
        api.stat('bottom_stat_list', {
            'stat_type': 'interest',
            'search_start_date': startDate,
            'search_end_date': endDate,
            page
        }).then((data)=>{
            setTotal(data.data.total_count)
            setList(data.list)
        })
    },[startDate, endDate, page])

    useEffect(()=>{
        apiStat();
    },[page, apiStat])

    const dateChange = (e) =>{
        const {name, value} = e.target;
        if(name === 'start'){
            setStartDate(value)
        }

        if(name === 'end'){
            setEndDate(value)
        }
    }
    return (
        <>
            <SubTitle>관심도</SubTitle>
            <Number total={true}/>
            <Graph />
            <div className='search-period'>
                <div>
                    <input type="date" name='start' value={startDate} onChange={dateChange}/>
                    <span>~</span>
                    <input type="date" name='end' value={endDate} onChange={dateChange}/>
                </div>
                <button className='btn-search' onClick={apiStat}>조회</button>
                {/* <button className='btn-excel'>엑셀 다운로드</button> */}
            </div>
            <div className='boardBox'>
                <div className='title'>
                    <span>날짜</span>
                    <span>관심도</span>
                </div>
                <ul className='list'>
                {list && list.map((data, idx)=> ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) && (
                        <li key={idx}>
                            <time>{data.stat_date}</time>
                            <p>{}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
}

