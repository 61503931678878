import React, { useEffect, useState } from 'react';

export default function ChatPopupImg({imgFile, setImgFile, messageSend}) {
    const [img , setImg] = useState();
    
    useEffect(()=>{
        const reader = new FileReader();

        reader.onloadend = () => {
            setImg(reader.result);
        }

        reader.readAsDataURL(imgFile);
    },[])

    const popupClose = (type) =>{
        setImgFile('');
    }
    return (
        <div className='popup2 absolute' onClick={popupClose}>
            <div className="img" onClick={(e)=>e.stopPropagation()}>
                <div>
                    <strong>{imgFile.name}</strong>
                    <img src={img} alt="미리보기 이미지" />
                </div>
                <button onClick={()=>messageSend('img')}>전송</button>
            </div>
        </div>
    );
}

