// 이메일 판별
export function email(data){
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(data);
}

// 비밀번호 판별
export function password(data){
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;
    return regex.test(data);
}

// 핸드폰번호 판별
export function mobile(data){
    const regex = /^01[0-9]{9,9}$/;
    return regex.test(data);
}

export function number(data){
    const regex = /^[0-9]+$/;
    return regex.test(data);
}

// 숫자 ',' 찍기
export function addCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}