import React from 'react';

export default function Graph() {
    return (
        <div className='graphBox'>
            <img src={require('../../images/center/graph.png')} alt="graph img" />
        </div>
    );
}

