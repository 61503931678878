import React, { useRef, useState } from 'react';
import * as api from '../api/api';
import { useNavigate } from 'react-router-dom';

export default function Popup({children , type , popup , setPopup , popupLink ,click , absolute}) {
    const brandText = useRef(null);
    const [brandList , setBrandList] = useState();
    const navigate = useNavigate();
    const brandSearch = () => {
        api.brandSearch({'name' : brandText.current.value})
            .then(setBrandList)
    }
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            brandSearch()
        }
    }

    const messageClick = () =>{
        !!click ? click() : setPopup('')
        popupLink === '새로고침' ? window.location.reload() :
            (popupLink && navigate(popupLink))
    }

    const test = () => {
        console.log(brandList);
    }
    return (
        <div className={`popup ${absolute ? 'absolute' : ''}`} onClick={() => setPopup('')} >
            <div className={type} onClick={(e)=>e.stopPropagation()}>
                {(type === 'message') && (
                    <>
                        <p>{popup}</p>
                        <button onClick={messageClick}>확인</button>
                    </>
                )}
                {type === 'brand' && (
                    <>
                        <strong onClick={test}>브랜드 검색</strong>
                        <div>
                            <input type="text" ref={brandText} onKeyDown={onKeyDown}/>
                            <button onClick={brandSearch}>검색</button>
                        </div>
                        <small>
                            브랜드가 검색되지 않을 시 <a href="mailto:help@changuptalk.co.kr">help@changuptalk.co.kr</a>로
                            메일 문의 부탁드립니다.
                        </small>
                        {brandList && (
                            <ul>
                                {brandList.map((list) => <li key={list.franchise_id} onClick={()=>click(list.franchise_id ,list.business_mark)}>{list.business_mark}</li>)}
                            </ul>
                        )}
                    </>
                )}
                {!(type === 'message') && children}
            </div>
        </div>
    );
}

