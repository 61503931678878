import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SubTitle from '../../../components/center/SubTitle';

export default function MyPage({children}) {
    const navigate = useNavigate();
    const logout = () =>{
        localStorage.removeItem('token')
        document.cookie = `token=`
        navigate('/')
    }
    return (
        <>
            <div className='profileArea'>
                <SubTitle>계정관리</SubTitle>
                <img src={require('../../../images/delete/profile.png')} alt="" />
                <p><strong>{localStorage.getItem('brand_name')}</strong>{localStorage.getItem('name') !== 'null' ? `(${localStorage.getItem('name')})` :''}</p>
                <ul>
                    <li><NavLink to="/center/my/infoChange">회원 정보 수정</NavLink></li>
                    {/* <li><NavLink to="/center/my/contactUs">문의하기</NavLink></li> */}
                    <li><NavLink to="/center/my/serviceInfo">서비스 정보</NavLink></li>
                    {/* <li><NavLink to="/center/my/announcement">공지사항</NavLink></li> */}
                    <li><button onClick={logout}>로그아웃</button></li>
                </ul>
            </div>
            <div className='subArea'>
                {children}
                {children && 
                    // <button>닫기</button>
                    <Link to="/center/my/">닫기</Link>
                }
            </div>
        </>
    );
}

