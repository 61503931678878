import React, { useRef, useState } from 'react';
import * as api from '../../api/api';

export default function PopupBrand({setBrandResult}) {
    const brandText = useRef(null);
    const [brandList , setBrandList] = useState();
    const brandSearch = () => {
        api.brandSearch({'name' : brandText.current.value})
            .then(setBrandList)
    }
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            brandSearch()
        }
    }
    return (
        <>
            <strong>브랜드 검색</strong>
            <div>
                <input type="text" ref={brandText} onKeyDown={onKeyDown}/>
                <button onClick={brandSearch}>검색</button>
            </div>
            <small>
                브랜드가 검색되지 않을 시 <a href="mailto:help@changuptalk.co.kr">help@changuptalk.co.kr</a>로
                메일 문의 부탁드립니다.
            </small>
            {brandList && (
                <ul>
                    {brandList.map((list) => <li key={list.franchise_id} onClick={()=>setBrandResult({'brand_name': list.franchise_id, 'franchise_id': list.business_mark})}>{list.business_mark}</li>)}
                </ul>
            )}
        </>
    );
}

