import React, { useContext, useEffect, useState } from 'react';
import * as api from '../../api/api';
import { Link } from 'react-router-dom';
import Brand from '../../components/center/main/Brand';
import Ranking from '../../components/center/Ranking';
import { MyContext } from '../../context/MyContext';

export default function Main() {
    const {responsive} = useContext(MyContext);
    const [dashboard, setDashboard] = useState();
    useEffect(()=>{
        api.dashboard().then(setDashboard)
    },[])

    const test = () => {
        console.log(dashboard);
    }
    return (
        <>
            <h2 onClick={test}><mark>{localStorage.getItem('brand_name')}</mark> 브랜드 매니저님, 반가워요:&#41;</h2>
            <ul className="inquiryArea">
                <li>
                    <b>새로운 창업 문의</b>
                    <Link to="/center/talk/chat" className='inquiry'><span>{dashboard?.new_chat_cnt}</span></Link>
                </li>
                <li>
                    <b>읽지 않은 톡</b>
                    <Link to="/center/talk/chat" className='message'><span>{dashboard?.no_read_talk_message_cnt}</span></Link>
                </li>
                <li>
                    <b>새로운 창업 문의</b>
                    <p>
                        현재 <Link to="/center/management/store">{dashboard?.total_store_cnt}</Link>의 지점<br/>
                        <Link to="/center/management/store">{dashboard?.contract_ing_cnt}</Link>의 지점이 계약 진행중
                    </p>
                </li>
            </ul>
            <Link to='/center/marketing'>
                <img src={
                    responsive ?
                    require('../../images/center/banner.png') :
                    require('../../images/center/m-banner.png') 
                } alt="브랜드와 가맹점주 모두를 위한 마케팅을 진행해보세요!" />
            </Link>
            <div>
                <section className='statisticsArea'>
                    <h3>우리 브랜드 통계</h3>
                    {dashboard && 
                        <Brand standy_date={dashboard.standy_date} pageview={dashboard.pageview} interest={dashboard.interest} />
                    }
                </section>
                <section className='noticeArea'>
                    <h3>공지사항</h3>
                        <ul>
                            {dashboard?.notice.map((data)=> (
                                <li key={data.notice_id}>
                                    {/* 상세 페이지 없어서 일단 클릭 막음 */}
                                    <Link to="/center/my/announcement" onClick={(e)=>e.preventDefault()}>
                                        <p>{data.title}</p>
                                        <time>{data.reg_date}</time>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                </section>

                <section className='rankingArea'>
                    <h3>실시간 랭킹</h3>
                    {dashboard &&
                        <Ranking 
                            my_ranking1={dashboard.my_ranking1}
                            my_ranking2={dashboard.my_ranking2}
                            my_ranking3={dashboard.my_ranking3}
                            ranking1 = {dashboard.ranking1}
                            ranking2 = {dashboard.ranking2}
                            ranking3 = {dashboard.ranking3}
                        />
                    }
                </section>
            </div>
        </>
    );
}

