import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import UserVersionLink from '../UserVersionLink';

export default function CenterNav({setIsMenu}) {
    const [active , setActive] = useState('')
    const menu = [
        {
            top: '창업톡',
            subMenu: [
                {
                    name: '1:1 대화',
                    link: 'talk/chat'
                },
                {
                    name: '문구관리',
                    link: 'talk/comment'
                }
            ]
        },
        {
            top: '가맹관리',
            subMenu: [
                {
                    name: '브랜드 정보 관리',
                    link: 'management/brand'
                },
                {
                    name: '지점관리',
                    link: 'management/store'
                }
            ]
        },
        {
            top: '마케팅',
            link: 'marketing',
        },
        {
            top: '통계',
            subMenu: [
                {
                    name: '페이지뷰',
                    link: 'statistics/pageView'
                },
                {
                    name: '관심도',
                    link: 'statistics/interest'
                },
                {
                    name: '랭킹 통계',
                    link: 'statistics/ranking'
                }
            ]
        },
        {
            top: '포인트',
            subMenu: [
                {
                    name: '충전하기',
                    link: 'point/charge'
                },
                {
                    name: '충전내역',
                    link: 'point/chargecHistory'
                },
                {
                    name: '사용내역',
                    link: 'point/usageHistory'
                }
            ]
        }
    ]
    return (
        <nav>
            <ul>
                {menu.map((first , idx)=>{
                    return (
                        <li key={idx}>
                            {first.link ? 
                                <NavLink to={first.link} onClick={()=>setIsMenu(false)}>{first.top}</NavLink> :
                                <button onClick={()=>setActive(first.top)} className={first.top === active ? 'active' : ''}>{first.top}</button>
                            }
                            {first.top === active && 
                                <ul>
                                    {first.subMenu.map((sub , subIdx)=>{
                                        return (
                                            <li key={subIdx}>
                                                <NavLink to={`/center/${sub.link}`} onClick={()=>setIsMenu(false)}>{sub.name}</NavLink>
                                            </li>
                                        )
                                    })}

                                </ul>
                            }
                        </li>
                    )
                })}
            </ul>
            <UserVersionLink />
        </nav>
    );
}

