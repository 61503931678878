import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import SubTitle from '../../../components/center/SubTitle';
import { Link, useParams } from 'react-router-dom';

export default function StoreDetail() {
    const { id } = useParams();
    const [storeDate, setStoreData] = useState();
    useEffect(()=>{
        api.store('detail', {'store_id': id}).then((data)=> {
            setStoreData(data.data)
        })
    },[id])
    return (
        <div>
            <SubTitle>지점 관리</SubTitle>
            <ul className='textList'>
                <li><span>관리 업체 비활성화 시 해당업체는 로그인 후 드라이버 관리 및 등록은 가능하나 배차운영에서 배제됩니다. </span></li>
                <li><span>사업자 등록증과 계약서사본은 필수입력사항이 아니며 등록 후 상세화면 <b>[수정하기]</b>에서 업데이트가 가능합니다.</span></li>
            </ul>
            
            <div className="boardBox">
                <div className="title">
                    <span>지점명</span>
                    <span>가맹점주</span>
                    <span>가맹점주 연락처</span>
                    <span>진행상태</span>
                </div>
                <ul className="list">
                    <li>
                        <div>{storeDate?.store_name}</div>
                        <div>{storeDate?.owner_name}</div>
                        <div>{storeDate?.owner_contact}</div>
                        <div>{storeDate?.state_comment}</div>
                    </li>
                </ul>
            </div>

            <div className="boardBox">
                <div className="title">
                    <span>지점주소</span>
                    <span>지점 전화번호</span>
                    <span>오픈일/오픈예정일</span>
                </div>
                <ul className="list">
                    <li>
                        <div>{storeDate?.address}</div>
                        <div>{storeDate?.store_contact}</div>
                        <div>{storeDate?.open_date}</div>
                    </li>
                </ul>
            </div>

            <div className="boardBox">
                <div className="title">
                    <span>사업자 등록증</span>
                    <span>계약서 사본</span>
                </div>
                <ul className="list">
                    <li>
                        <div>
                            <a href={storeDate?.store_company_register_file ?? ''} rel="noreferrer" target='_blank' className='btn-border-small'>파일보기</a>
                            <a href={storeDate?.store_company_register_file ?? ''} rel="noreferrer" target='_blank' className='btn-basic-small'>다운로드</a>
                        </div>
                        <div>
                            <a href={storeDate?.store_contract_copy_file ?? ''} rel="noreferrer" target='_blank' className='btn-border-small'>파일보기</a>
                            <a href={storeDate?.store_contract_copy_file ?? ''} rel="noreferrer" target='_blank' className='btn-basic-small'>다운로드</a>
                        </div>
                    </li>
                </ul>
            </div>

            <b>관련 메모</b>
            <textarea name='memo' readOnly value={storeDate?.memo ?? ''}></textarea>
            <div className='btnArea'>
                <button onClick={()=>window.history.go(-1)} className='btn-border'>이전화면</button>
                <Link to={`/center/management/storeUpdate/${id}`} className='btn-basic'>수정하기</Link>
            </div>
        </div>
    );
}

