import React, { useEffect, useState } from 'react';
import * as api  from '../../api/api';
import * as validation  from '../../api/validation';
import Popup from '../../components/popup/Popup';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({'marketing_info_receive_yn': 'n'});
    const [popup, setPopup] = useState('');
    const [popupLink, setPopupLink] = useState('');
    const [passwordFirst, setPasswordFirst] = useState('');
    const [mobileSend, setMobileSend] = useState();
    const [mobileConfirm, setMobileConfirm] = useState();
    const [business, setBusiness] = useState('');
    const [agree, setAgree] = useState(false)
    const [brandResult, setBrandResult] = useState({});

    useEffect(()=>{
        const token = api.getCookieValue('token') || localStorage.getItem('token');
        if(token){navigate('/center')}
    },[navigate])

    // 브랜드
    useEffect(()=>{
        setInputs((input)=> ({...input, ...brandResult}))
        setPopup('')
    },[brandResult])

    // input 데이터 저장
    const inputChange = (e) => {
        const {name, value, checked , files} = e.target;
        // 비밀번호 
        if(name === 'passwordFirst') {
            validation.password(value) && setPasswordFirst(value)
            return
        }
        // 비밀번호 확인
        if(name === 'password'){
            passwordFirst === value ? setInputs((input)=> ({...input, [name]: value})) : setInputs((input)=> ({...input, [name]: ''}))
            return;
        }
        
        // 핸드폰 번호
        if(name === 'mobile'){
            !validation.mobile(value) && setMobileSend(false)
        }

        // 핸드폰 번호 인증
        if(name === 'mobileConfirm'){
            setMobileConfirm(value);
            return;
        }
        
        // 사업자 등록증
        if(name === 'business_license_file'){
            console.log(files);
            setInputs((input)=> ({...input, [name]: files[0]}))
            return;
        }

        //이용약관과 개인정보 정책
        if(name === 'agree_yn'){
            setAgree(checked)
            return
        }


        // 마케팅 정보 수신 동의
        if(name === 'marketing_info_receive_yn'){
            setInputs((input)=> ({...input, [name]: checked ? 'y' : 'n'}))
            return;
        }


        setInputs((input)=> ({...input, [name]: value}))
    }

    
    // 핸드폰 인증 번호 보내기
    const mobileClick = (type) => {
        let data = {
            'req_type' :'join'
        }
        if(type === 'send'){
            if(validation.mobile(inputs.mobile)){
                data = {...data, 'fucn': 'req', 'mobile': inputs.mobile};
                api.mobile(data).then((data) => {
                    setPopup(data.error_message)
                    data.result && setMobileSend(true);
                })
            } else {
                setPopup('휴대폰 번호를 확인해주세요.');
            }
        }
        
        if(type === 'confirm'){
            data = {...data, 'fucn': 'chk', 'mobile': inputs.mobile, 'auth_number': mobileConfirm};
            api.mobile(data).then((data) => {
                if(data.result){
                    setInputs((input)=> ({...input, 'mobile_auth_id': Number(data.data.mobile_auth_id)}))
                    setPopup(data.error_message)
                    setMobileSend(false);
                }else{
                    setPopup('인증번호를 확인해주세요.')
                }
            })
        }
    }

    const deleteBtn = (type) =>{
        if(type === 'business') {
            let data = {...inputs}
            delete data.business_license_file;
            delete data.business_license_file_id;
            setInputs(data)
            setBusiness(undefined)
        }
    }

    const onSubmit = async () =>{
        let data = {...inputs}
        console.log(inputs);
        if(!data.email){
            setPopup('이메일을 작성해주세요.') 
            return;
        }
        if(!validation.email(data.email)){
            setPopup('이메일을 확인해주세요.') 
            return;
        }
        if(!data.password){
            setPopup('비밀번호를 확인해주세요.') 
            return;
        }
        if(!data.name){
            setPopup('이름을 작성해주세요.') 
            return;
        }
        if(!validation.mobile(data.mobile)){
            setPopup('휴대폰 번호를 확인해주세요.') 
            return;
        }
        if(!data.mobile_auth_id){
            setPopup('휴대폰 인증을 작성해주세요.') 
            return;
        }
        // if(!data.franchise_id){
        if(!data.brand_name){
            setPopup('브랜드 정보를 작성해주세요.') 
            return;
        }
        
        if(!data.hasOwnProperty('business_license_file_id') && data.hasOwnProperty('business_license_file')){
            const businessId = await api.image(inputs.business_license_file);
            data.business_license_file_id = parseInt(businessId);
            setInputs((input)=> ({...input, 'business_license_file_id': parseInt(businessId)}))
        }
        
        if(data.hasOwnProperty('business_license_file')){
            delete data.business_license_file;
        }

        if(!data.business_license_file_id){
            setPopup('사업자 등록증을 등록해주세요.') 
            return;
        }
        if(!agree){
            setPopup('이용약관과 개인정보 정책에 동의해주세요.') 
            return;
        }

        delete data.business_license_file;

        api.signUp(data).then((data)=>{
            setPopup(data.error_message);
            setPopupLink('/login');
        })
    }
    
   
    return (
        <section>
            <h2>파트너센터 회원가입</h2>
            <form onSubmit={(e)=>e.preventDefault()}>
                <fieldset>
                    <legend>회원 정보</legend>
                    <ul>
                        <li>
                            <label htmlFor="">아이디 (이메일주소)</label>
                            <input type="text" title='이메일' id="email" name="email" value={inputs.email ?? ''} onChange={inputChange}/>
                        </li>
                        <li>
                            <label htmlFor="">비밀번호</label>
                            <input type="password" title="비밀번호" id="passwordFirst" name="passwordFirst" onChange={inputChange}/>
                            <p>PW 8~15자리 영문+숫자+특수문자조합</p>
                        </li>
                        <li>
                            <label htmlFor="">비밀번호 확인</label>
                            <input type="password" title="비밀번호 확인" id="password" name="password" onChange={inputChange} readOnly={!passwordFirst}/>
                        </li>
                        <li>
                            <label htmlFor="">성함</label>
                            <input type="text" title="성함" id="name" name="name" value={inputs.name ?? ''} onChange={inputChange} />
                        </li>
                        <li>
                            <label htmlFor="">직급</label>
                            <input type="text" title="직급" id="company_position" name="company_position" value={inputs.company_position ?? ''} onChange={inputChange}/>
                        </li>
                        <li>
                            <label htmlFor="">휴대폰 번호 (본인인증)</label>
                            <div>
                                <input type="text" title="휴대폰 번호" id="mobile" name="mobile" maxLength={11} onChange={inputChange}/>
                            </div>
                            <button onClick={()=>mobileClick('send')}>인증번호 전송</button>
                        </li>
                        {mobileSend && 
                            <li id="auth_number_div">
                                <label htmlFor="">인증 번호</label>
                                <div>
                                    <input type="text" title="인증번호" id="mobileConfirm" name="mobileConfirm" maxLength={6} onChange={inputChange}/>
                                </div>
                                <button onClick={()=>mobileClick('confirm')}>인증번호 확인</button>
                            </li>
                        }
                    </ul>
                </fieldset>
                <fieldset>
                    <legend>사업자 정보</legend>
                    <ul>
                        <li>
                            <label htmlFor="">브랜드 정보</label>
                            <div>
                                <input type="text" name="brand_name" id="brand_name" value={/* brandResult.brand_name */ inputs?.brand_name ?? ''} /* readOnly */ onChange={inputChange}/>
                                {/* <button className="XBtn" onClick={()=>setBrandResult({})}>입력 값 제거</button> */}
                            </div>
                            {/* <button data-click="brand" onClick={()=>setPopup('brand')}>브랜드 검색</button> */}
                        </li>
                        <li>
                            <label htmlFor="">사업자 등록증</label>
                            <div>
                                <input type="text" value={inputs?.business_license_file?.name ?? ''} readOnly data-input="addFill" />
                                <button className="XBtn" onClick={()=>deleteBtn('business')}>입력 값 제거</button>
                            </div>
                            <label htmlFor="addFill">사업자 등록증 추가</label>
                            <input type="file" id="addFill" value={business ?? ''} accept="image/*" name="business_license_file" onChange={inputChange}/>
                        </li>
                    </ul>
                </fieldset>
                <fieldset>
                    <legend className="hiddenText">동의</legend>
                    <input type="checkbox" id="agree_yn" name="agree_yn" onChange={inputChange} />
                    <label htmlFor="agree_yn">이용약관과 개인정보 정책에 동의합니다. <mark>(필수)</mark></label>
                    <input type="checkbox" id="marketing_info_receive_yn" name="marketing_info_receive_yn"  onChange={inputChange} />
                    <label htmlFor="marketing_info_receive_yn">마케팅 정보 수신에 동의합니다. (선택)</label>
                </fieldset>
                <input type="submit" value="회원가입" onClick={onSubmit}/>
            </form>
            {popup && <Popup popup={{popup , setPopup}} setBrandResult={setBrandResult} popupLink={popupLink} />}
        </section>
    );
}

  