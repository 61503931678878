import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';

export default function BrandPromotion({promotion, setPromotion, setPopup}) {
    const [inputs, setInputs] = useState();
    const [scopeList, setScopeList] = useState();
    const [isSubmit, setIsSubmit] = useState();

    useEffect(()=>{
        api.brand('scope').then((data)=>{
            let copyData = [...data.list]
            copyData.forEach((data)=>{
                if(data.options){
                    data.options = data.options.split('|');
                }
            })
            setScopeList(copyData)
        })
        let copyData = {...promotion, 'support_scope_list': {...promotion.support_scope_list}}
        setInputs(copyData);
    },[promotion])

    useEffect(()=>{
        if(!inputs || !promotion){return}
        setIsSubmit(Object.entries(promotion).some(([key, value]) => {
            if(typeof value === 'object' && value){
                const prev = Object.keys(value).length >= Object.keys(inputs[key]).length ? value : inputs[key]
                const next = Object.keys(value).length < Object.keys(inputs[key]).length ? value : inputs[key]
                return Object.entries(prev).some(([key2, value2])=>{
                    return value2 !== next[key2]
                })
            }else{
                return value !== inputs[key]
            }
        }))
    },[inputs, setIsSubmit, promotion])

    const inputChange = (e) => {
        const {name, value, id, checked} = e.target;

        let copyData = {...inputs}
        if(name === 'support_scope_list'){ // 체크 박스
            if(checked){
                scopeList.some((a)=>(a.conts_id === id && a.options)) ?
                    copyData.support_scope_list[id] = '1년' :
                    copyData.support_scope_list[id] = false
                
            }else{
                delete copyData.support_scope_list[id]
            }
            setInputs(copyData);
        } else if(!isNaN(parseInt(name))){ // 셀렉트
            copyData.support_scope_list[name] = value;
        } else { // 체크박스 , 셀렉트 외
            copyData = {...copyData,[name]: value}
        }

        setInputs(copyData)
    }

    const onSubmit = (e) =>{
        e.preventDefault()
        let data = {...inputs};
        data.support_scope_list = []
        Object.entries(inputs.support_scope_list).forEach(([conts_id, option_value])=>{
            const isOption = typeof option_value === 'string' ? {conts_id, option_value} : {conts_id}
            data.support_scope_list.push(isOption)
        })

        api.brandUpdate('promotion_update', data).then((data)=>{
            if(data.result){
                let copyData = {...inputs, 'support_scope_list': {...inputs.support_scope_list}}
                setPromotion(copyData)
                setPopup(data.error_message)
            }
        })
    }
    return (
        <form>
            <fieldset className='promotionArea'>
                <legend>창업 프로모션 여부</legend>
                <mark className='blue'>
                    관심도 및 가맹으로 이어지는 지름길! 창업비 지원 프로모션 :&#41;<br />
                    진행 시에만 면제항목에 체크해주세요
                </mark>
                <div className="boardBox">
                    <ul className='list'>
                        <li>
                            <b>창업비 지원 프로모션</b>
                            <div>
                                <input type="radio" id='promotion_y' value={'y'} checked={inputs?.promotion_yn === 'y'} name='promotion_yn' onChange={inputChange}/>
                                <label htmlFor="promotion_y">적용</label>
                                <input type="radio" id='promotion_n' value={'n'} checked={inputs?.promotion_yn === 'n'} name='promotion_yn' onChange={inputChange}/>
                                <label htmlFor="promotion_n">미적용</label>
                            </div>
                        </li>
                        <li>
                            <b>지원대상</b>
                            <div>
                                초기
                                <input type="number" name='init_promotion_store_count' value={inputs?.init_promotion_store_count ?? ''} onChange={inputChange}/>
                                호점까지 창업비를 지원합니다.
                            </div>
                        </li>
                        <li>
                            <b>
                                지원 범위
                                <mark className='blue'>
                                    체크하신 비용은<br/>
                                    연제로 처리됩니다
                                </mark>
                            </b>
                            <div>
                                {scopeList && scopeList.map((data)=>
                                    <div key={data.conts_id}>
                                        <input type="checkbox" id={data.conts_id} name='support_scope_list' checked={inputs.support_scope_list.hasOwnProperty(data.conts_id)} onChange={inputChange}/>
                                        <label htmlFor={data.conts_id}>{data.value}</label>
                                        {data.options &&
                                            <select name={data.conts_id} value={inputs.support_scope_list[data.conts_id]} disabled={!inputs.support_scope_list[data.conts_id]} onChange={inputChange}>
                                                {data.options.map((data, idx)=>
                                                    <option key={idx} value={data}>{data}</option>
                                                )}
                                            </select>
                                        }
                                    </div>
                                )}
                               {/*  <div>
                                    <input type="checkbox" id='11' value='11' name='support_scope_list' onChange={inputChange}/>
                                    <label htmlFor="11">보증금</label>
                                </div>
                                <input type="checkbox" id='12' value='12' name='support_scope_list' onChange={inputChange}/>
                                <label htmlFor="12">교육비</label>
                                <input type="checkbox" id='13' value='13' name='support_scope_list' onChange={inputChange}/>
                                <label htmlFor="13">가맹비</label>
                                <input type="checkbox" id='14' value='14' name='support_scope_list' onChange={inputChange}/>
                                <label htmlFor="14">인테리어</label>
                                <div>
                                    <input type="checkbox" id='15' value='15' name='support_scope_list' onChange={inputChange}/>
                                    <label htmlFor="15">로열티</label>
                                    <select>
                                    </select>
                                </div>
                                <div>
                                    <input type="checkbox" id='16' value='16' name='support_scope_list' onChange={inputChange}/>
                                    <label htmlFor="16">광고/판촉비</label>
                                    <select>
                                    </select>
                                </div> */}
                            </div>
                        </li>
                    </ul>
                </div>
                <input type="submit" value="등록하기" className='btn-basic' disabled={!isSubmit} onClick={onSubmit}/>
            </fieldset>
        </form>
    );
}

