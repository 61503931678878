import React, { useContext, useEffect, useRef, useState } from 'react';
import * as api from '../../../api/api';
import Popup from '../../Popup';
import ChatPopupPhrases from './ChatPopupPhrases';
import { MyContext } from '../../../context/MyContext';
import ChatPopupImg from './ChatPopupImg';
import ChatImgShow from './ChatImgShow';

export default function ChatWindow({setIsChatWindow , messageData, setMessageData}) {
    const {responsive} = useContext(MyContext);
    const [popup , setPopup] = useState('')
    const scrollRef = useRef(null);
    const heightRef = useRef(null);
    const textareaRef = useRef(null)
    const [isTextareaHeight, setIsTextareaHeight] = useState()
    const [addOption , setAddOption] = useState(false)
    const [lastMessage, setLastMessage] = useState('');
    const [message, setMessage] = useState();
    const [imgFile, setImgFile] = useState();
    const [phrases, setPhrases] = useState(false);
    const [imgShowLink , setImgShowLink] = useState();

    useEffect(() => {
        let isLastMessage = setInterval(() => {
            api.message('list', {'chat_id': messageData.data.chat_id, 'last_message_id': messageData.list.at(-1).message_id}).then((data)=>{
                setLastMessage(data)
            })
        }, 500);
        return () =>{
            clearInterval(isLastMessage)
        }
    }, [messageData, setMessageData]);
    
    useEffect(()=>{
        setTimeout(()=>{
            scrollRef.current.scrollTo(0 , heightRef.current.clientHeight)
        },200)
    },[messageData, message])

    useEffect(()=>{
        message ? textareaHeight() : textareaHeight('init');
    },[message, responsive])
    
    const textareaHeight = (type)=>{
        const textarea = textareaRef.current
        let newHeight;
        type === 'init' ? (responsive ? (newHeight = 70 + 'px') : (newHeight = 50 + 'px')) : newHeight = textarea.scrollHeight + 'px';
        setIsTextareaHeight(newHeight)
    }

    const imgShow = (imgLink) =>{
        setImgShowLink(imgLink);
    }

    const messageChange = (e) =>{
        const { value } = e.target;
        setMessage(value)
    }
    
    const textareaKeyDown = (e) =>{
         if(e.key === 'Enter' && !e.shiftKey){
            e.preventDefault();
            messageSend()
        }
    
    }

    const messageSend = async () =>{
        if(!message && !imgFile){return}
        let imgData;
        if(imgFile){
            await api.image(imgFile).then((id)=>{
                imgData = {file_id: id}
            })
        }
        api.message('write', {'chat_id': messageData.data.chat_id, 'message': message , ...imgData}).then((data)=>{
            if(data.result){
                setMessage('');
                setImgFile('');
            }
        })
    }
    return (
        <div className='chatArea'>
            {/* 상단 */}
            <div className="topArea">
                <h3>{messageData.data.name}</h3>
                <button className='info' onClick={()=>setPopup('info')}>정보 보기</button>
                <button className='close' onClick={()=>setIsChatWindow('')}>닫기</button>
            </div>
            {/* 채팅 */}
            <div className='messageArea' ref={scrollRef}>
                <div ref={heightRef}>
                    <b><span>{messageData.data.reg_date}  대화가 시작되었습니다.</span></b>
                    <p>
                        전화연락을 선호하는 예비점주님입니다.<br/>
                        왼쪽 상단 버튼을 눌러 미리 입력한 <button>창업정보</button>를 확인해보세요.
                    </p>
                    {messageData.list && messageData.list.map((data)=> {
                        if(!data.message && !data.message_media_file) {return null}
                        return (
                            <div className={data.write_yn === 'n' ? 'opponent' : 'me'} key={data.message_id} style={{whiteSpace: 'pre-line'}}>
                                {data.message && 
                                    <div className="textArea">
                                        {data.message ?? ' '}
                                    </div>
                                }
                                {data.message_media_file && <img src={data.message_media_file} alt='이미지' onClick={()=>imgShow(data.message_media_file)}/>}
                                <time>{data.reg_date_hi}</time>
                            </div>
                        )}
                    )}
                    {lastMessage && lastMessage.list.map((data)=>
                        <div className={data.write_yn === 'n' ? 'opponent' : 'me'} key={data.message_id} style={{whiteSpace: 'pre-line'}}>
                            {data.message && 
                                <div className="textArea">
                                    {data.message ?? ' '}
                                </div>
                            }
                            {data.message_media_file && <img src={data.message_media_file} alt='이미지'/>}
                            <time>{data.reg_date_hi}</time>
                        </div>
                    )}
                </div>
            </div>
            {/* 하단 */}
            <div className="buttomArea">
                <button className='add' onClick={()=> setAddOption(!addOption)}>추가</button>
                <div>
                    <textarea type="text" style={{height: isTextareaHeight}}
                        value={message ?? ''} ref={textareaRef} onChange={messageChange} onKeyDown={textareaKeyDown}></textarea>
                    <button className='send' onClick={messageSend}>보내기</button>
                </div>
            </div>
            {/* 추가 영역 */}
            {addOption && 
                <ul className='addArea'>
                    <li className='camera'>
                        <input type="file" id="cameraFile" capture="environment" onChange={(e)=> setImgFile(e.target.files[0])}/>
                        <label htmlFor="cameraFile">카메라</label>
                    </li>
                    <li className='img'>
                        <input type="file" id="imgFile" accept="image/*" onChange={(e)=> setImgFile(e.target.files[0])}/>
                        <label htmlFor="imgFile">사진첩</label>
                    </li>
                    {/* <li className='file'>파일 전송</li> */}
                    <li className='phrases' onClick={()=>setPhrases(true)}>자주 쓰는 문구</li>
                </ul>
            }

            {imgFile &&
                <ChatPopupImg imgFile={imgFile} setImgFile={setImgFile} messageSend={messageSend}/>
            }

            {phrases &&
                <ChatPopupPhrases setPhrases={setPhrases} setMessage={setMessage} setAddOption={setAddOption}/>
            }

            {imgShowLink && 
                <ChatImgShow imgShowLink={imgShowLink} setImgShowLink={setImgShowLink}/>
            }
            
            {popup &&
                <Popup type="info" setPopup={setPopup} absolute>
                    {popup === 'info' &&
                        <>
                            <strong>예비점주 창업 정보</strong>
                            <p>
                                1:1 대화를 신청하며<br/>
                                예비점주님이 작성한 사항입니다.<br/>
                                상담에 참고해주세요.
                            </p>
                            <ul>
                                <li>
                                    성함
                                    <b>{messageData.data.name}</b>
                                </li>
                                <li>
                                    연락처
                                    <b>{messageData.data.mobile}</b>
                                </li>
                                <li>
                                    창업 희망 지역
                                    <b>{messageData.data.area}</b>
                                </li>
                                <li>
                                    전화상담 선호
                                    <div>
                                        <input type="radio" readOnly checked={messageData.data.phone_counsel_preference_yn === 'y'}/>
                                        <label htmlFor="">예</label>
                                        <input type="radio" readOnly checked={messageData.data.phone_counsel_preference_yn === 'n'}/>
                                        <label htmlFor="">아니오</label>
                                    </div>
                                </li>
                                {/* <li>
                                    선호 시간대
                                    <b>오후 4시</b>
                                </li> */}
                                <li>
                                    자료 수신용 메일 주소
                                    <b style={{wordBreak: 'break-all'}}>{messageData.data.email}</b>
                                </li>
                            </ul>
                            <button className='close' onClick={()=> setPopup('')}>닫기</button>
                        </>
                    }
                </Popup>
            }
        </div>
    );
}

