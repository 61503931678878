import React, { useState } from 'react';
import MyPage from './MyPage';

export default function ServiceInfo() {
    const [popupData, setPopupData] = useState(false);
    return (
        <MyPage>
            <h3>서비스 정보</h3>
            <div className="boardBox">
                <ul className="list">
                    <li>
                        <a href="http://changtalk.co.kr/policy/service.html" target='_blank' rel="noreferrer">
                            서비스 이용약관
                        </a>
                    </li>
                    <li>
                        <a href="http://changtalk.co.kr/policy/privacy.html" target='_blank' rel="noreferrer">
                            개인정보보호정책
                        </a>
                    </li>
                </ul>
            </div>
            {popupData &&
                <div className='servicePopup'>
                    <strong>서비스 이용약관</strong>
                    <b>제목 텍스트 입니다.</b>
                    <p>
                        텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.
                        텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.
                    </p>
                    <b>제목 텍스트 입니다.</b>
                    <p>
                        텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.
                        텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.
                    </p>
                    <button onClick={()=>setPopupData(false)}>닫기</button>
                </div>
            }
        </MyPage>
    );
}

