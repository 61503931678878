import React from 'react';
import { Link } from 'react-router-dom';

export default function Main() {
    return (
        <>
            <section className="topArea">
                <div className="contentsSize">
                    <h2>가맹본사와 점주를 이어주는 가장 간편한 방법</h2>
                    <p>오늘부터 창업톡으로 <b>394,384</b>명의 예비 점주들을 만나보세요.</p>
                    {/* <ul>
                        <li><Link href="/pages/input/counsel.php">상담신청</Link></li>
                        <li><Link href="/">브랜드 입점신청</Link></li>
                        <li><Link href="/">가맹 사업 관리</Link></li>
                    </ul> */}
                    <ul>
                        <li>상담신청</li>
                        <li>브랜드 입점신청</li>
                        <li>가맹 사업 관리</li>
                    </ul>
                </div>
            </section>
            <section className="storeArea contentsSize">
                <h2>
                    <small><mark>지점 관리</mark></small>
                    오늘부터<br/>
                    가맹점 유치와 지점관리는<br/>
                    창업톡 파트너센터에서!
                </h2>
                <p>
                    지점관리를 위한 각종 서류 및 메모 정리?! <br/>
                    예비점주님들과의 대화 히스토리 관리?!<br/>
                    흩어져서 고생할 필요없이 파트너센터에서 한 번에!
                </p>
            </section>
            <section className="brandArea contentsSize">
                <h2>
                    <small>브랜드 관리</small>
                    예비점주들에게 보여지는<br/>
                    브랜드 이미지 창업톡에서<br/>
                    확실하게 관리해요
                </h2>
                <p>
                    다양한 통계들로 확인해보는 우리 브랜드 이미지로<br/>
                    예비 점주분들에게 더욱 좋은 인상을 남기고 <br/>
                    계약으로 이어가요.
                </p>
            </section>
            <section className="owneArea contentsSize">
                <h2>
                    <small><mark>점주 관리</mark></small>
                    모든 일의 시작은 대화로 부터!<br/>
                    소통이 가장 편리한<br/>
                    창업톡으로 시작하세요.
                </h2>
                <p>
                    단순한 정보 제공을 넘어 예비점주와 브랜드 매니저의 1:1대화<br/>
                    를 이끌어내는 시스템 창업톡과 함께하면 구상만하던 프랜차이<br/>
                    즈 사업이 현실이 됩니다. 
                </p>
            </section>
            <section className="FAQArea contentsSize">
                <h2>FAQ</h2>
                <ul>
                    <li>
                        <details>
                            <summary>창업톡은 무료 서비스인가요?</summary>
                            <p>
                                예비점주들에게 노출되는 브랜드 정보 등록은 100% 무료 서비스로 진행됩니다.<br/>
                                이후 실제 진행을 원하는 예비점주들의 톡 요청이 있을 시에 한하여 포인트 차감.<br/>
                                형태로 유료상품이 적용되어 가장 효율적으로 비용 집행이 가능합니다.
                            </p>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>지점관리 메뉴는 어떻게 쓰는건가요?</summary>
                            <p>
                                예비점주들에게 노출되는 브랜드 정보 등록은 100% 무료 서비스로 진행됩니다.<br/>
                                이후 실제 진행을 원하는 예비점주들의 톡 요청이 있을 시에 한하여 포인트 차감.<br/>
                                형태로 유료상품이 적용되어 가장 효율적으로 비용 집행이 가능합니다.
                            </p>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>브랜드 정보 부분 디자인 지원이 필요해요</summary>
                            <p>
                                예비점주들에게 노출되는 브랜드 정보 등록은 100% 무료 서비스로 진행됩니다.<br/>
                                이후 실제 진행을 원하는 예비점주들의 톡 요청이 있을 시에 한하여 포인트 차감.<br/>
                                형태로 유료상품이 적용되어 가장 효율적으로 비용 집행이 가능합니다.
                            </p>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>본사 차원에서 진행할 수 있는 마케팅이 있나요?</summary>
                            <p>
                                예비점주들에게 노출되는 브랜드 정보 등록은 100% 무료 서비스로 진행됩니다.<br/>
                                이후 실제 진행을 원하는 예비점주들의 톡 요청이 있을 시에 한하여 포인트 차감.<br/>
                                형태로 유료상품이 적용되어 가장 효율적으로 비용 집행이 가능합니다.
                            </p>
                        </details>
                    </li>
                </ul>
            </section>
        </>
    );
}

