import React, { useEffect, useRef, useState } from 'react';
import * as api from '../../../api/api';
import SubTitle from '../../../components/center/SubTitle';
import Popup from '../../../components/Popup';

export default function Comment() {
    const [commentList, setCommentList] = useState();
    const textareaRef = useRef();
    const [popup, setPopup] = useState();
    const test = () =>{
        console.log(commentList);
    }

    useEffect(()=>{
        initList();
    },[])
    
    const initList = () =>{
        api.comment('select').then((data)=>setCommentList(data.list))
        textareaRef.current.value = ''
        textareaRef.current.blur();
    }

    // 추가하기
    const registrationBtn = () =>{
        if(textareaRef.current.value){
            api.comment('write',{'comment': textareaRef.current.value}).then((data)=>{
                if(data.result){
                    initList()
                    setPopup(data.error_message)
                }
            })
        } else {
            setPopup('자주쓰는 문구를 입력하세요.')
        }
    }
    
    // 삭제하기
    const deleteBtn = (id) =>{
        console.log(textareaRef.current.value);
        api.comment('delete',{'default_comment_id': id}).then((data)=>{
            if(data.result){
                initList()
            }
            setPopup(data.error_message)
        })
    }

    return (
        <>
            <SubTitle>문구관리</SubTitle>
            <p onClick={test}>최대 10개 등록 가능</p>
            <textarea placeholder='자주쓰는 문구를 입력하세요' ref={textareaRef} onKeyDown={(e)=>e.key === 'Enter' && registrationBtn()} ></textarea>
            <button className='btn-basic' onClick={registrationBtn}>등록하기</button>
            <strong>등록된 문구</strong>
            <div className='boardBox'>
                <ul className='list'>
                    {commentList && commentList.map((list)=>
                        <li key={list.default_comment_id}>
                            <div style={{whiteSpace: 'pre-line'}}>{list.comment}</div>
                            <button onClick={()=>deleteBtn(list.default_comment_id)}>삭제</button>
                        </li>
                    )}
                    {/* <li>
                        <div>
                            안녕하세요. <br/>메가커피 김길동 매니저입니다. <br/>통화상담은 오후 1시부터 가능하며  <br/>지정해주신 시간대에 <br/>연락드릴 수 있도록 하겠습니다. <br/>감사합니다.
                        </div>
                        <button>삭제</button>
                    </li>
                    <li>
                        <div>
                            안녕하세요. <br/>메가커피 김길동 매니저입니다. <br/>통화상담은 오후 1시부터 가능하며  <br/>지정해주신 시간대에 <br/>연락드릴 수 있도록 하겠습니다. <br/>감사합니다.
                        </div>
                        <button>삭제</button>
                    </li>
                    <li>
                        <div>
                            안녕하세요. <br/>메가커피 김길동 매니저입니다. <br/>통화상담은 오후 1시부터 가능하며  <br/>지정해주신 시간대에 <br/>연락드릴 수 있도록 하겠습니다. <br/>감사합니다.
                        </div>
                        <button>삭제</button>
                    </li>
                    <li>
                        <div>
                            안녕하세요. <br/>메가커피 김길동 매니저입니다. <br/>통화상담은 오후 1시부터 가능하며  <br/>지정해주신 시간대에 <br/>연락드릴 수 있도록 하겠습니다. <br/>감사합니다.
                        </div>
                        <button>삭제</button>
                    </li>
                    <li>
                        <div>
                            안녕하세요. <br/>메가커피 김길동 매니저입니다. <br/>통화상담은 오후 1시부터 가능하며  <br/>지정해주신 시간대에 <br/>연락드릴 수 있도록 하겠습니다. <br/>감사합니다.
                        </div>
                        <button>삭제</button>
                    </li> */}
                </ul>
            </div>
            {popup &&
                <Popup type="message" popup={popup} setPopup={setPopup} absolute/>
            }
        </>
    );
}

