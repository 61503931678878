import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '../../../api/api';
import * as validation  from '../../../api/validation';
import MyPage from './MyPage';
import Popup from '../../../components/Popup';

export default function InfoChange() {
    const [currentData, setCurrentData] = useState();
    const [inputs, setInputs] = useState();
    const [mobileUpdate, setMobileSend] = useState(false);
    const [mobile, setMobile] = useState();
    const [mobileConfirm, setMobileConfirm] = useState();
    const [isSubmit,setIsSubmit] = useState();
    const [popup, setPopup] = useState('');
    const mobileRef = useRef(null);

    const profileInit = useCallback(()=>{
        let profileData = JSON.parse(localStorage.getItem('profile'));
        profileData = {...profileData, business_name: profileData.business_license_file.split('/').at(-1)}
        setInputs(profileData)
        setCurrentData(profileData)
        setMobile(profileData.mobile);
    },[])

    useEffect(()=>{
        profileInit()
    },[profileInit])

    const inputChange = (e) =>{
        const {name, value, files} = e.target;
        if(name === 'mobile') {
            setMobile(value)
            return;
        }
        if(name === 'mobileConfirm') {
            setMobileConfirm(value)
            return;
        }

        if(name ==='business_license_file'){
            setInputs((input)=> ({...input, [name]: files[0], 'business_name': files[0].name}))
            return
        }
        setInputs((input)=> ({...input, [name]: value}))
    }

    useEffect(()=>{
        if(!inputs || !currentData){return}
        setIsSubmit(Object.entries(currentData).some(([key, value]) => {
            return value !== inputs[key]
        }))
    },[inputs , currentData])

    const mobileClick = (type) =>{
        if(type === 'update') {
            // setMobileSend(true)
            mobileRef.current.value = ''
            mobileRef.current.focus();
        }

        
        let data = {
            'req_type' :'update'
        }
        
        if(type === 'send') {
            if(validation.mobile(mobile)){
                data = {...data, 'fucn': 'req', 'mobile': mobile};
                api.mobile(data).then((data)=>{
                    if(data.result){
                        setPopup(data.error_message)
                        setMobileSend(true)
                    }
                })
            }else{
                setPopup('휴대폰 번호를 확인해주세요.')
            }
        }
        
        if(type === 'confirm'){
            data = {...data, 'fucn': 'chk', 'mobile': mobile, 'auth_number': mobileConfirm};
            api.mobile(data).then((data) => {
                if(data.result){
                    setInputs((input)=> ({...input, 'mobile':mobile, 'mobile_auth_id': Number(data.data.mobile_auth_id)}))
                    setPopup(data.error_message)
                    setMobileSend(false);
                }else{
                    setPopup('인증번호를 확인해주세요.')
                }
            })
        }
    }



    const onSubmit = async () =>{
        let data = {...inputs};
        if(data.hasOwnProperty('business_license_file')){
            if(typeof data.business_license_file === 'string'){
                // console.log('data file' , typeof data.business_license_file);
                // data.file1 = data.business_license_file
            } else {
                const businessId = await api.image(data.business_license_file)
                data.business_license_file_id = businessId
                delete data.business_license_file
            }
        }

        // console.log('data',data);

        api.profile(data).then((data)=>{
            // console.log(data);
            if(data.result){
                setPopup(data.error_message);
                // setPopupLink('/center/my/infoChange')
                setIsSubmit(false)
                localStorage.setItem('profile',JSON.stringify({
                    name: data.data.name,
                    brand_name: data.data.brand.brand_name,
                    company_position: data.data.company_position,
                    mobile: data.data.mobile,
                    talk_name: data.data.talk_name,
                    business_license_file: data.data.business_license_file
                }))

                profileInit()
            }
        })
    }

    return (
        <MyPage>
            <form onSubmit={(e)=>e.preventDefault()}>
                <fieldset>
                    <legend>회원 정보 수정</legend>
                    <ul>
                        <li>
                            <label htmlFor="">성함</label>
                            <div>
                                <input type="text" name='name' value={inputs?.name ?? ''} onChange={inputChange}/>
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">직급</label>
                            <div>
                                <input type="text" name='company_position' value={inputs?.company_position ?? ''} onChange={inputChange}/>
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">대화명</label>
                            <div>
                                <input type="text" name='talk_name' value={inputs?.talk_name ?? ''} onChange={inputChange}/>
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">휴대폰 번호</label>
                            <div>
                                <input type="text" name='mobile' value={mobile ?? ''} onChange={inputChange} ref={mobileRef} maxLength='11'/>
                                <button onClick={()=>mobileClick(mobile === currentData?.mobile ? 'update' : 'send')}>
                                    {mobile === currentData?.mobile ? '수정' : '전송'}
                                </button>
                            </div>
                        </li>
                        {mobileUpdate && 
                            <li>
                                <label htmlFor="">휴대폰 번호 인증</label>
                                <div>
                                    <input type="number" name='mobileConfirm' value={mobileConfirm ?? ''} onChange={inputChange} maxLength={6}/>
                                    <button onClick={()=>mobileClick('confirm')}>확인</button>
                                </div>
                            </li>
                        }
                        <li>
                            <label htmlFor="">브랜드 명</label>
                            <div>
                                <input type="text" name='brand_name' readOnly value={inputs?.brand_name ?? ''} onChange={inputChange}/>
                            </div>
                            <mark className='blue'>브랜드 명 변경을 원할 시 문의하기로 요청해주세요</mark>
                        </li>
                        <li>
                            <label htmlFor="">사업자 등록증</label>
                            <div>
                                <div>
                                    <input type="text"  value={inputs?.business_name ?? ''} readOnly/>
                                    {/* 제거 버튼 일단 보류.. 지울 시 css도 지워야 한다 */}
                                    {/* <button className='btn-close' onClick={imgDelete}>제거</button> */}
                                </div>
                                <input type="file" id='business_license_file' name='business_license_file' onChange={inputChange}/>
                                <label htmlFor='business_license_file'>사업장 등록증 추가</label>
                            </div>
                        </li>
                    </ul>
                    <input type="submit" value='수정하기' disabled={!isSubmit} onClick={onSubmit}/>
                </fieldset>
            </form>
            {popup && 
                <Popup type="message" popup={popup} setPopup={setPopup} absolute/>
            }
        </MyPage>
    );
}

