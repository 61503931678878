import React, { useState } from 'react';
import MyPage from './MyPage';

export default function ContactUs() {
    const tabList = ['문의하기' , '나의 문의 내역'];
    const [tabActive, setTabActive] = useState(tabList[0])
    return (
        <MyPage>
            <h3>문의하기</h3>
            <ul className='tabBox'>
                {tabList.map((data , idx)=>{
                    return(
                        <li key={idx} className={data === tabActive ? 'active' : ''} onClick={()=>setTabActive(data)}>{data}</li>
                    )
                })}
            </ul>
            {tabActive === '문의하기' &&
                <form>
                    <fieldset>
                        <ul>
                            <li>
                                <label htmlFor="">제목</label>
                                <div>
                                    <input type="text" placeholder='제목을 입력해주세요'/>
                                </div>
                            </li>
                            <li>
                                <label htmlFor="">문의사항</label>
                                <div>
                                    <textarea placeholder='문의사항을 입력해주세요'></textarea>
                                </div>
                            </li>
                            <li>
                                <label htmlFor="">첨부파일</label>
                                <div>
                                    
                                </div>
                            </li>
                        </ul>
                        <input type="submit"  value="수정하기"/>
                    </fieldset>
                </form>
            }
            {tabActive === '나의 문의 내역' &&
                <div className="boardBox">
                    <ul className="list">
                        <li className=''>
                            <details>
                                <summary>
                                    <strong>버전 업데이트가 진행될 예정이에요!</strong>
                                    <time>2021.12.18 14:08</time>
                                </summary>
                                <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                                <ul className='imgArea'>
                                    <li>
                                        img
                                    </li>
                                    <li>
                                        img
                                    </li>
                                    <li>
                                        img
                                    </li>
                                </ul>
                            </details>
                        </li>
                        <li className='answer'>
                            <details>
                                <summary>
                                    <strong>버전 업데이트가 진행될 예정이에요!</strong>
                                    <time>2021.12.18 14:08</time>
                                </summary>
                                <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                                <ul className='imgArea'>
                                    <li>
                                        img
                                    </li>
                                    <li>
                                        img
                                    </li>
                                    <li>
                                        img
                                    </li>
                                </ul>
                                <div className="answerArea">
                                    <b>답변</b>
                                    <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                                </div>
                            </details>
                        </li>
                    </ul>
                </div>
            }
        </MyPage>
    );
}

