import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserVersionLink from '../UserVersionLink';

export default function MainHeader({subPageName}) {
    const [headerBG, setHeaderBG] = useState('');
    useEffect(() => {
        if(subPageName === 'signUp'){ return }
        const handleScroll = () => {
            window.scrollY > 0 ? setHeaderBG('') : setHeaderBG('transparent')
        };
        
        handleScroll()
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          setHeaderBG('')
        };
      }, [subPageName]);
    return (
        <header className={headerBG}>
            <div className="contentsSize">
                <h1><Link to="/"><img src={require('../../images/common/logo-partners.png')} alt="창업톡 for Partners" /></Link></h1>
                <nav>
                    <Link to="/login">로그인</Link>
                    <Link to="/main/signUp">회원가입</Link>
                </nav>
                <UserVersionLink />
            </div>
        </header>
    );
}

