import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import BrandRepresentative from '../../../components/center/management/BrandRepresentative';
import BrandPromotion from '../../../components/center/management/BrandPromotion';
import BrandCost from '../../../components/center/management/BrandCost';
import BrandInfo from '../../../components/center/management/BrandInfo';
import SubTitle from '../../../components/center/SubTitle';
import Popup from '../../../components/Popup';

export default function Brand() {
    const tabList = ['대표정보' , '프로모션','창업비용','브랜드 정보'];
    const [tabActive, setTabActive] = useState(tabList[0])
    const [popup, setPopup] = useState('');
    const [isPublic, setIsPublic] = useState();
    const [representative, setRepresentative] = useState();
    const [cost, setCost] = useState();
    const [promotion, setPromotion] = useState();
    const [info, setInfo] = useState();
    useEffect(()=>{
        api.brand('detail').then((data)=>{
            setIsPublic(data?.data.public_yn)

            setRepresentative({
                'brand_name': data.data.brand_name,
                'brand_logo_file': data.data.brand_logo_file,
                'brand_main_store_file': data.data.brand_main_store_file,
                'store_count': data.data.store_count,
                'category_id': data.data.category_id
            })
            let copyData = {'start_up_money_info' : {} , 'company_paiement_money': {}}
            data.data.start_up_money_info !== null &&
            data.data.start_up_money_info.forEach((data)=> {
                copyData.start_up_money_info[data.name] = parseInt(data.value)
            })
            
            data.data.company_paiement_money !== null &&
            data.data.company_paiement_money.forEach((data)=>{
                copyData.company_paiement_money[data.name] = parseInt(data.value)
            })

            copyData = {...copyData, 
                'standard_store_area': data.data.standard_store_area === null ? 0 : parseInt(data.data.standard_store_area),
                'start_up_money': data.data.start_up_money === null ? 0 : parseInt(data.data.start_up_money),
            }

            setCost(copyData)

            copyData = {
                'promotion_yn': data.data.promotion_yn,
                'init_promotion_store_count': data.data.init_promotion_store_count,
                'support_scope_list': {}
            }
            
            data.data.company_paiement_money !== null &&
                data.data.scope_list.forEach((data)=>{
                    copyData.support_scope_list[data.conts_id] = data.option_value;
                })
            setPromotion(copyData);

            copyData = {
                'brand_video_link_path': data.data.brand_video_link,
                'brand_video_file_path': data.data.brand_video_file,
                'brand_detail_info': data.data.brand_detail_info,
                'menu_list': [...data.data.menu_list],
                'brand_interior_file_path': data.data.brand_interior_file
            }
            copyData.menu_list.forEach((menuList)=>{
                menuList.menu_file_path = menuList.menu_file;
                delete menuList.menu_file;
            })
            setInfo(copyData)
        })
    },[])

    const publicChange = (e) =>{
        const { value } = e.target;
        api.brandUpdate('brand_info_public', {'public_yn': value}).then((data)=>{
            setIsPublic(data.data.public_yn)
            setPopup(data.error_message)
        })
    }
    return (
        <>
            <div className='detailArea'>
                <SubTitle>브랜드 정보 관리</SubTitle>
                <input type="radio" name='isPublic' id="public" checked={isPublic === 'y'} value={'y'} onChange={publicChange}/>
                <label htmlFor="public">공개</label>
                <input type="radio" name='isPublic' id="private" checked={isPublic === 'n'} value={'n'} onChange={publicChange}/>
                <label htmlFor="private">비공개</label>
                <ul className='tabBox'>
                    {tabList.map((data , idx)=>{
                        return(
                            <li key={idx} className={data === tabActive ? 'active' : ''} onClick={()=>setTabActive(data)}>{data}</li>
                        )
                    })}
                </ul>
                {/* 대표 정보 */}
                {tabList[0] === tabActive &&
                    <BrandRepresentative representative={representative} setRepresentative={setRepresentative} setPopup={setPopup}/>
                }
                {/* 프로모션 */}
                {tabList[1] === tabActive &&
                    <BrandPromotion promotion={promotion} setPromotion={setPromotion} setPopup={setPopup}/>
                }
                {/* 창업 비용 */}
                {tabList[2] === tabActive &&
                    <BrandCost cost={cost} setCost={setCost} setPopup={setPopup}/>
                }
                {/* 브랜드 정보 */}
                {tabList[3] === tabActive &&
                    <BrandInfo info={info} setInfo={setInfo} setPopup={setPopup}/>
                }
                {popup && 
                    <Popup type="message" popup={popup} setPopup={setPopup} absolute/>
                }
            </div>
            <div className='previewArea'>
                <strong>미리보기</strong>
                <img src={require('../../../images/center/preview.png')} alt="모바일 미리보기 이미지" />
            </div>
        </>
    );
}

