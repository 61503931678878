import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainHeader from '../components/header/MainHeader';
import Footer from '../components/Footer';
import CenterHeader from '../components/header/CenterHeader';

export default function Root() {
    const location = useLocation().pathname;
    const pageName = location.substring(1).split('/')[0];
    const subPageName = location.substring(1).split('/')[1] || 'home' ;
    const subPageName2 = location.substring(1).split('/')[2] || '' ;
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);
    return (
        <div className={`${pageName}Page`}>
            {pageName === 'main' && <MainHeader subPageName={subPageName}/>}
            {pageName === 'center' && <CenterHeader />}
            <main className={`${subPageName}Page`}>
                {pageName === 'main' && <Outlet /> }
                {pageName === 'center' && ( 
                    <div className={subPageName2 && `${subPageName2}Page`}>
                        <Outlet /> 
                    </div>
                )}
            </main>
            {pageName === 'main' && <Footer />}
        </div>
    );
}

