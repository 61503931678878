import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';

export default function BrandRepresentative({representative, setRepresentative, setPopup}) {
    const [inputs, setInputs] = useState();
    const [category, setCategory] = useState();
    const [logoFile, setLogoFile] = useState();
    const [storeFile, setStoreFile] = useState();
    const [isSubmit, setIsSubmit] = useState();

    useEffect(()=>{
        api.brand('category').then((data)=>{
            setCategory(data.list)
        })
    },[])

    useEffect(()=>{
        setInputs(representative)
    },[representative])

    useEffect(()=>{
        if(!inputs){return}
        setIsSubmit(Object.entries(representative).some(([key, value]) => {
            return value !== inputs[key]
        }))
    },[inputs, representative])

    // input 데이터 저장
    const inputChange = (e) => {
        const {name, value , files} = e.target;

        if(name === 'brand_logo_file') {
            const reader = new FileReader();

            reader.onloadend = () => {
                setLogoFile(reader.result);
            }

            reader.readAsDataURL(files[0]);
        }
        
        if(name === 'brand_main_store_file') {
            const reader = new FileReader();

            reader.onloadend = () => {
                setStoreFile(reader.result);
            }

            reader.readAsDataURL(files[0]);
        }

        setInputs((input)=> ({...input, [name]: files ? files[0] : value}))
    }
    
    const onSubmit = async (e) => {
        e.preventDefault();
        let data = {...inputs}
        if(representative.brand_logo_file !== inputs.brand_logo_file){
            await api.image(inputs.brand_logo_file).then((id)=>{
                data.brand_logo_file_id = parseInt(id)
            })
        } else {
            delete data.brand_logo_file_id;
            data.brand_logo_file_path = ''
        }

        if(representative.brand_main_store_file !== inputs.brand_main_store_file){
            await api.image(inputs.brand_main_store_file).then((id)=>{
                data.brand_main_store_file_id = parseInt(id)
            })
        } else {
            delete data.brand_main_store_file_id;
            data.brand_main_store_file_path = ''
        }
        
        api.brandUpdate('main_update', data).then((data)=>{
            if(data.result){
                setPopup(data.error_message)
                setRepresentative({...inputs})
            }
        })
    }

    return (
        <form>
            <fieldset className='representativeArea'>
                <legend>대표정보</legend>
                <div className="boardBox">
                    <ul className='list'>
                        <li>
                            <b>브랜드명</b>
                            <b>{inputs?.brand_name}</b>
                        </li>
                        <li>
                            <b>BI (브랜드로고)</b>
                            <div>
                                <input type="file" id='brand_logo_file' name='brand_logo_file' accept="image/*" onChange={inputChange}/>
                                <label htmlFor="brand_logo_file" className='camera'>
                                    {inputs?.brand_logo_file ? 
                                        <img src={
                                            representative.brand_logo_file === inputs.brand_logo_file ?
                                            inputs.brand_logo_file : logoFile
                                        } alt='브랜드 로고'/>
                                        : '이미지 추가'}
                                </label>
                                <mark className='blue'>3MB이하 정사각형 이미지 권장</mark>
                            </div>
                        </li>
                        <li>
                            <b>대표 매장 이미지</b>
                            <div>
                                <input type="file" id='brand_main_store_file' name='brand_main_store_file' accept="image/*" onChange={inputChange}/>
                                <label htmlFor="brand_main_store_file" className='camera'>
                                    {inputs?.brand_main_store_file ? 
                                        <img src={
                                            representative.brand_main_store_file === inputs.brand_main_store_file ?
                                            inputs.brand_main_store_file : storeFile
                                        } alt='브랜드 로고'/>
                                        : '이미지 추가'}
                                    </label>
                                <mark className='blue'>1개 이미지</mark>
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">창업 카테고리</label>
                            <div>
                                <select onChange={inputChange} name='category_id' value={inputs?.category_id}>
                                    {inputs?.category_id ?? <option value='' defaultValue={true}></option>}
                                    {category &&
                                        category.map((list)=>
                                           <option value={list.category_id} key={list.category_id}>
                                                {list.category}
                                            </option>
                                    )}
                                </select>
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">점포 수</label>
                            <div>
                                <input type="number" value={inputs?.store_count ?? ''} name='store_count' onChange={inputChange}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <input type="submit" value="등록하기" className='btn-basic' disabled={!isSubmit} onClick={onSubmit}/>
            </fieldset>
        </form>
    );
}

