import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import Popup from '../../../components/Popup';
import ChatWindow from '../../../components/center/chat/ChatWindow';
import SubTitle from '../../../components/center/SubTitle';

export default function Chat() {
    const [popup , setPopup] = useState('')
    const [popupLink, setPopupLink] = useState('');
    const [isSearch , setIsSearch] = useState(false)
    const [newId, setNewId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [point, setPoint] = useState('');
    const [isChatWindow , setIsChatWindow] = useState('');
    const [list, setList] = useState();
    const [messageData, setMessageData] = useState();

    useEffect(()=>{
        chatListApi();
    },[])

    const chatListApi = () =>{
        api.chat('list').then((data)=>{
            setList(data.list)
        })
    }

    useEffect(()=>{
        if(isChatWindow){
            api.message('list', {'chat_id': isChatWindow}).then((data)=>{
                setMessageData(data);
            })
        }
    },[isChatWindow])

    function listClass(isNew , id){
        let className = ''
        isNew === 'n' && (className += 'new')
        isChatWindow === id && (className += ' active')
        return className;
    }

    const listClick = (isNew, id, point) => {
        if (isNew === 'n'){
            setPopup('open')
            setNewId(id)
            setPoint(point)
        }

        if (isNew === 'y'){
            setIsChatWindow(id)
        }
    }
   
    const openPopupBtn = (type) =>{
        if(type) {
            api.chat('open', {'chat_id': newId}).then((data)=>{
                if(data.result_code === 3001){
                    setPopup('포인트가 부족합니다.')
                    setPopupLink('/center/point/charge')
                }else{
                    setIsChatWindow(newId)
                    setPopup('')
                    setNewId('')
                }
            })
        } else {
            setPopup('')
            setNewId('')
        }
    }

    const deleteBtn = (e, id) =>{
        e.stopPropagation();
        setPopup('delete')
        setDeleteId(id);
    }
    
    const deletePopupBtn = (type) =>{
        if(type){
            api.chat('delete', {'chat_id': deleteId}).then((data)=>{
                setMessageData('')
                setIsChatWindow('')
                chatListApi()
            })
        }
        setPopup('')
    }
    return (
        <>
            <div className='listArea'>
                <div className='topArea'>
                    <SubTitle>1:1 대화</SubTitle>
                    {/* 추후 검색 기능 만들어야 한다 */}
                    {/* <button onClick={()=>setIsSearch(true)}>검색창 오픈</button> */}
                    {isSearch &&
                        <div>
                            <input type="text" placeholder='성함을 검색해보세요'/>
                            <button onClick={()=>setIsSearch(false)}>검색</button>
                        </div>
                    }
                </div>
                {list?.length ? 
                    <ul>
                        {list.map((data) => {
                            return (
                                <li key={data.chat_id} className={listClass(data.point_open_yn, data.chat_id)} onClick={()=> listClick(data.point_open_yn, data.chat_id,data.open_point_price)}>
                                    <strong>{data.name}</strong>
                                    <p>{data.last_message}</p>
                                    <time>{data.last_message_date}</time>
                                    {data.point_open_yn === 'n' &&
                                        <button className='open'>{data.open_point_price}</button>
                                    }
                                    <button className='delete' onClick={(e)=>deleteBtn(e, data.chat_id)}>삭제</button>
                                </li>
                            )
                        })}
                    </ul>
                    :
                    <div className='textArea'>
                        <strong>요청된 1:1 대화가 없습니다.</strong>
                        <p>
                            브랜드 정보를 공개 등록 혹은<br/>
                            이미 등록된 브랜드 정보를<br/>
                            매력있는 컨텐츠로 수정해주세요.
                        </p>
                    </div>
                }
                { popup && (
                    (popup === 'open' || popup === 'delete') ?
                    <Popup type="btn" setPopup={setPopup} absolute>
                        {popup === 'open' &&
                            <>
                                <p>
                                    해당 대화를 활성화하시려면 <mark>{point}P 차감</mark>이 필요합니다.
                                    진행하시겠습니까?
                                </p>
                                <button onClick={()=> openPopupBtn(true)}>확인</button>
                                <button onClick={() => openPopupBtn(false)}>취소</button>
                            </>
                        }
                        {popup === 'delete' && 
                            <>
                                <p>
                                    나가기 시 상대방의 모든 정보와
                                    대화 내용이 삭제됩니다.
                                    계속하시곘습니까?
                                </p>
                                <button onClick={()=> deletePopupBtn(true)}>확인</button>
                                <button onClick={() => deletePopupBtn(false)}>취소</button>
                            </>
                        }
                    </Popup> :
                    <Popup type="message" popup={popup} setPopup={setPopup} popupLink={popupLink} absolute/>
                )}
            </div>
            {/* 채팅 */}
            {(messageData && isChatWindow) &&
                <ChatWindow setIsChatWindow={setIsChatWindow} messageData={messageData} setMessageData={setMessageData}/>
            }
        </>
    );
}

