import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import { useLocation } from 'react-router-dom';

export default function Number({total, rankingData}) {
    const pageName = useLocation().pathname.substring(1).split('/').at(-1).toLowerCase();
    const [number, setNumber] = useState();
    useEffect(()=>{
        pageName === 'ranking' || 
            api.stat('top_summary_info', {'stat_type': pageName}).then((data)=>{
                setNumber(data.data)
            })
    },[pageName])
    if(!number && !rankingData){return}
    return (
        <ul className='numberBox'>
           <li>
                <p>
                    {pageName === 'pageview' && '실시간 페이지뷰'}
                    {pageName === 'interest' && '실시간 관심도'}
                    {pageName === 'ranking' && `실시간 관심도 ${rankingData?.interestRank ? `(${rankingData.interestRank}위)` : ''}`}
                </p>
                {!rankingData ? 
                    <>
                        <b>{number?.today_stat || 0}</b>
                        {/* <mark className='down'>171</mark> */}
                    </>
                    :
                    rankingData?.interest?.recent_week_stat ? 
                        <>
                            <b>{rankingData?.interest?.recent_week_stat}</b>
                        </> :
                        <b>0</b>
                }
            </li>
            <li>
                <p>
                    {pageName === 'pageview' && '최근 일주일 평균 페이지뷰'}
                    {pageName === 'interest' && '최근 일주일 평균 관심도'}
                    {pageName === 'ranking' && `실시간 창업톡 수 ${rankingData?.talkRank ? `(${rankingData.talkRank}위)` : ''}`}
                </p>
                {!rankingData ? 
                    number?.recent_week_stat ? 
                        <>
                            <b>{parseInt(number?.recent_week_stat) + parseInt(number?.prev_week_stat)}</b>
                            <mark className={number.recent_week_stat > number.prev_week_stat ? 'up' : 'down'}>
                                {Math.abs(number.recent_week_stat - number.prev_week_stat)}
                            </mark>
                        </> :
                        <b>0</b>
                    :
                    rankingData?.talk?.recent_week_stat ? 
                        <>
                            <b>{rankingData?.talk?.recent_week_stat}</b>
                            {/* <mark className={number.recent_week_stat > number.prev_week_stat ? 'up' : 'down'}>
                                {Math.abs(number.recent_week_stat - number.prev_week_stat)}
                            </mark> */}
                        </> :
                        <b>0</b>
                }
            </li>
            <li>
                <p>
                    {pageName === 'pageview' && '3월달 평균 페이지뷰'}
                    {pageName === 'interest' && '3월달 평균 관심도'}
                    {pageName === 'ranking' && `실시간 점포 수 ${rankingData?.storeRank ? `(${rankingData.storeRank}위)` : ''}`}
                </p>
                
                {!rankingData ? 
                    number?.current_month ?
                        <>
                            <b>{parseInt(number?.current_month) + parseInt(number?.prev_month)}</b>
                            <mark className={number.current_month > number.prev_month ? 'up' : 'down'}>
                                {Math.abs(number.current_month - number.prev_month)}
                            </mark>
                        </> :
                        <b>0</b>
                    :
                    rankingData?.store ? 
                        <>
                            <b>{rankingData?.store?.recent_week_stat}</b>
                            {/* <mark className={number.recent_week_stat > number.prev_week_stat ? 'up' : 'down'}>
                                {Math.abs(number.recent_week_stat - number.prev_week_stat)}
                            </mark> */}
                        </> :
                        <b>0</b>
                }
            </li>
            {total &&
                <li>
                    <p>
                        {pageName === 'pageview' && '총 누적 페이지뷰'}
                        {pageName === 'interest' && '총 누적 관심 수'}
                    </p>
                    <b>
                        {number?.total_stat}
                    </b>
                </li> 
            }
        </ul>
    );
}

