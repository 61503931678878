import React, { useEffect, useState } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import * as api from '../api/api';
import Popup from '../components/Popup';

export default function Login() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [popup, setPopup] = useState();

    useEffect(()=>{
        const token = api.getCookieValue('token') || localStorage.getItem('token');
        if(token){navigate('/center')}
    },[navigate])

    const inputChange = (e) => {
        const {name, value, checked, type} = e.target;
        setInputs((input)=> ({...input, [name]: type === 'checkbox' ? checked : value}))
    }
    

    const onSubmit = () =>{
        api.login(inputs, inputs.remember).then((data)=>{
            if(data.result){
                localStorage.setItem('name',data.data.name)
                localStorage.setItem('brand_name',data.data.brand.brand_name)
                localStorage.setItem('profile',JSON.stringify({
                    name: data.data.name,
                    brand_name: data.data.brand.brand_name,
                    company_position: data.data.company_position,
                    mobile: data.data.mobile,
                    talk_name: data.data.talk_name,
                    business_license_file: data.data.business_license_file
                }))
                navigate('/center')
            }else{
                setPopup(data.error_message)
            }
        })
    }

    return (
        <div className='loginPage'>
            <div>
                <h1><img src={require("../images/common/logo-partners.png")} alt="창업톡 for Partners" /></h1>
                <p>창업톡 파트너센터에 오신것을 환영합니다! </p>
                <small>파트너센터는 승인된 파트너만 이용가능합니다.</small>
                <form id="loginForm" onSubmit={(e)=>e.preventDefault()}>
                    <fieldset>
                        <input type="email" placeholder="이메일을 입력하세요." value={inputs.email ?? ''} name="email" onChange={inputChange}/>
                        <input type="password" placeholder="비밀번호를 입력하세요." value={inputs.password ?? ''} name="password" onChange={inputChange}/>
                        <div>
                            <input type="checkbox" name="remember" id="remember" onChange={inputChange}/>
                            <label htmlFor="remember">로그인 정보 기억하기</label>
                            <Link to="/main/signUp">파트너 가입하기</Link>
                        </div>
                        <input type="submit" value="로그인" onClick={onSubmit}/>
                    </fieldset>
                </form>
                {/* <Link to="/">이메일 혹은 비밀번호를 잊으셨나요?</Link> */}
                {popup &&
                    <Popup type="message" popup={popup} setPopup={setPopup}/>
                }
            </div>
        </div>
    );
}

