import React from 'react';
import Graph from '../Graph';

export default function Brand({standy_date, pageview, interest}) {
    return (
        <div>
            <h4>브랜드 페이지 뷰 그래프 <time>{standy_date ?? ''}</time></h4>
            <Graph />
            <ul>
                <li>
                    <p>실시간 페이지뷰</p>
                    <b>{Number(pageview.current_month) + Number(pageview.prev_month)}</b>
                    <mark className={pageview.current_month > pageview.prev_month ? 'up' : 'down'}>
                        {Math.abs(pageview.current_month - pageview.prev_month)}
                    </mark>
                </li>
                <li>
                    <p>실시간 관심증가</p>
                    <b>{Number(interest.current_month) + Number(interest.prev_month)}</b>
                    <mark className={interest.current_month > interest.prev_month ? 'up' : 'down'}>
                        {Math.abs(interest.current_month - interest.prev_month)}
                    </mark>
                </li>
                <li>
                    <p>총 누적관심 수</p>
                    <b>{pageview.total_stat}</b>
                </li>
                <li>
                    <p>총 누적 페이지뷰</p>
                    <b>{interest.total_stat}</b>
                </li>
            </ul>
        </div>
    );
}

