import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';

export default function ChatPopupPhrases({setPhrases , setMessage, setAddOption}) {
    const [commentList, setCommentList] = useState();
    const [commentSelect, setCommentSelect] = useState();

    useEffect(()=>{
        api.comment('select').then((data)=>{
            setCommentList(data.list)})
    },[])

    const phrasesChange = (e) =>{
        const { value } = e.target;
        setCommentSelect(value);
    }

    const popupClose = (type) =>{
        if(type === 'select'){
            setAddOption(false)
            setMessage(commentSelect)
        }

        setPhrases(false)
    }
    if(!commentList){return}
    return (
        <div className='popup2 absolute' onClick={popupClose}>
            <div className="phrases" onClick={(e)=>e.stopPropagation()}>
                <strong>자주쓰는 문구</strong>
                <div>
                    {commentList && 
                        <ul>
                            {commentList.map((data)=>
                                <li key={data.default_comment_id}>
                                    <input type="radio" id={data.default_comment_id} value={data.comment} name='commentChois' onChange={phrasesChange}/>
                                    <label htmlFor={data.default_comment_id} style={{whiteSpace: 'pre-line'}}>{data.comment}</label>
                                </li>
                            )}
                        </ul>
                    }
                </div>
                <button onClick={()=>popupClose('select')}>선택</button>
            </div>
        </div>
    );
}

