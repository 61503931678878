import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import * as validation  from '../../../api/validation';
import DaumPostcode from 'react-daum-postcode';
import SubTitle from '../../../components/center/SubTitle';
import Popup from '../../../components/Popup';
import { useParams } from 'react-router-dom';

export default function StoreUpdate() {
    const { id } = useParams();
    const [openPostcode, setOpenPostcode] = useState()
    const [inputs, setInputs] = useState({state: '1'});
    const [storeDate, setStoreData] = useState();
    const [popup, setPopup] = useState('');
    const [popupLink, setPopupLink] = useState('');
    const [isSubmit, setIsSubmit] = useState();

    useEffect(()=>{
        api.store('detail', {'store_id': id}).then((data)=> {
            let copyData = {...data.data}
            copyData = {...data.data, 'file1': data.data.store_company_register_file, 'file2': data.data.store_contract_copy_file}
            delete copyData.store_company_register_file;
            delete copyData.store_contract_copy_file;
            setInputs(copyData)
            setStoreData(copyData)
        })
    },[id])

    const addressOpen = () => {
        setOpenPostcode(current => !current);
    }
    const selectAddress = (data) =>{
        setOpenPostcode(current => !current);
        let copyData = {...inputs};
        if(copyData.hasOwnProperty('latitude')){
            delete copyData.latitude;
            delete copyData.longitude;
        }
        copyData = {...copyData, 'address': data.address}
        setInputs(copyData)
    }
    const close = () =>{
        setOpenPostcode(false);
    }

    useEffect(()=>{
        if(!inputs || !storeDate){return}
        setIsSubmit(Object.entries(storeDate).some(([key, value]) => {
            return value !== inputs[key]
        }))
    },[inputs, storeDate])

    const imgDelete = (type) =>{
        if(type === 'company'){
            let copyData = {...inputs}
            copyData.file1 && delete copyData.file1;
            copyData.store_company_register_file && delete copyData.store_company_register_file;
            setInputs(copyData);
        }

        if(type === 'contract'){
            let copyData = {...inputs}
            copyData.file2 && delete copyData.file2;
            copyData.store_contract_copy_file && delete copyData.store_contract_copy_file;
            setInputs(copyData);
        }
    }

    const storeDelete = () => {
        api.store('delete', {'store_id': id}).then((data)=>{
            if(data.result){
                setPopup(data.error_message);
                setPopupLink('/center/management/store')
            }
        })
    }

    const inputChange = (e) => {
        const {name, value, type, files} = e.target;
        if (name === 'store_company_register_file'){
            let copyData = {...inputs}
            delete copyData.file1;
            setInputs(()=> ({...copyData, [name]: files[0]}))
            return;
        }
        
        if (name === 'store_contract_copy_file') {
            let copyData = {...inputs}
            delete copyData.file2;
            setInputs(()=> ({...copyData, [name]: files[0]}))
            return;
        }
        setInputs((input)=> ({...input, [name]: type === 'file' ? files[0] : value}))
    }
    const onSubmit = async () =>{
        let data = {...inputs}
        
        if(!data.store_name){
            setPopup('지점명을 작성해주세요.') 
            return;
        }

        if(!data.owner_name){
            setPopup('가맹점주를 작성해주세요.')
            return;
        }

        if(!data.owner_contact){
            setPopup('가맹점주 연락처를 작성해주세요.') 
            return;
        }

        if(!validation.mobile(data.owner_contact)){
            setPopup('가맹점주 연락처를 확인해주세요.') 
            return;
        }

        if(!data.address){
            setPopup('지점주소를 작성해주세요.') 
            return;
        }

        if(!data.store_contact){
            setPopup('지점 전화번호를 작성해주세요.') 
            return;
        }

        if(!validation.mobile(data.store_contact)){
            setPopup('지점 전화번호를 확인해주세요.') 
            return;
        }

        if(!data.open_date){
            setPopup('오픈일/오픈예정일을 작성해주세요.') 
            return;
        }

        // 주소
        if(!data.hasOwnProperty('latitude')){
            const address = await api.address(data.address)
            data = {...data, ...address};
            data.latitude = Number(data.latitude)
            data.longitude = Number(data.longitude)
        }

        // 사업자 등록증
        if(!data.hasOwnProperty('store_company_register_file_id') && data.hasOwnProperty('store_company_register_file')){
            const companyId = await api.image(inputs.store_company_register_file);
            data.store_company_register_file_id = parseInt(companyId);
            setInputs((input)=> ({...input, 'store_company_register_file_id': parseInt(companyId)}))
        }
        
        if(data.hasOwnProperty('store_company_register_file')){
            delete data.store_company_register_file;
        }

        // 계약서 사본 등록
        if(!data.hasOwnProperty('store_contract_copy_file_id') && data.hasOwnProperty('store_contract_copy_file')){
            const contractId = await api.image(inputs.store_company_register_file);
            data.store_contract_copy_file_id = parseInt(contractId);
            setInputs((input)=> ({...input, 'store_contract_copy_file_id': parseInt(contractId)}))
        }
        
        if(data.hasOwnProperty('store_contract_copy_file')){
            delete data.store_contract_copy_file;
        }
        api.store('update', data).then((data)=>{
            if(data.result){
                setPopup(data.error_message);
                setPopupLink('/center/management/store')
            }
        })
    }
    return (
        <>
            <div onClick={close}>
                <SubTitle>추가 등록</SubTitle>
                <ul className='textList'>
                    <li><span>관리 업체 비활성화 시 해당업체는 로그인 후 드라이버 관리 및 등록은 가능하나 배차운영에서 배제됩니다. </span></li>
                    <li><span>사업자 등록증과 계약서사본은 필수입력사항이 아니며 등록 후 상세화면 <b>[수정하기]</b>에서 업데이트가 가능합니다.</span></li>
                </ul>
                
                <div className="boardBox">
                    <div className="title">
                        <span>지점명</span>
                        <span>가맹점주</span>
                        <span>가맹점주 연락처</span>
                        <span>진행상태</span>
                    </div>
                    <ul className="list">
                        <li>
                            <div>
                                <input type="text" name='store_name' value={inputs.store_name ?? ''} onChange={inputChange}/>
                            </div>
                            <div>
                                <input type="text" name='owner_name' value={inputs.owner_name ?? ''} onChange={inputChange}/>
                            </div>
                            <div>
                                <input type="text" name='owner_contact' maxLength={11} value={inputs.owner_contact ?? ''} onChange={inputChange}/>
                            </div>
                            <div>
                                <select name="state" value={inputs?.state ?? ''} onChange={inputChange}>
                                    <option value="1">계약중</option>
                                    <option value="2">오픈예정</option>
                                    <option value="3">오픈</option>
                                </select>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="boardBox">
                    <div className="title">
                        <span>지점주소</span>
                        <span>지점 전화번호</span>
                        <span>오픈일/오픈예정일</span>
                    </div>
                    <ul className="list">
                        <li>
                            <div style={{position: 'relative'}} onClick={(e)=>e.stopPropagation()}>
                                <input type="text" name='address' value={inputs.address ?? ''} onClick={addressOpen} onChange={inputChange}/>
                                {openPostcode && 
                                    <DaumPostcode style={{position: 'absolute', border: '1px solid black'}}
                                        onComplete={selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                        autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                                        />}
                            </div>
                            <div>
                                <input type="text" name='store_contact' maxLength={11} value={inputs.store_contact ?? ''}  onChange={inputChange}/>
                            </div>
                            <div>
                                <input type="date" name='open_date' value={inputs.open_date ?? ''} onChange={inputChange}/>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="boardBox">
                    <div className="title">
                        <span>사업자 등록증 등록</span>
                        <span>계약서 사본 등록</span>
                    </div>
                    <ul className="list">
                        <li>
                            <div>
                                <input type="file" name="store_company_register_file" id="store_company_register_file" accept="image/*" onChange={inputChange}/>
                                <input type="text" value={
                                    inputs?.file1 ? inputs?.file1.split('/').at(-1) :
                                    (inputs?.store_company_register_file?.name ?? '')
                                    } readOnly/>
                                <label htmlFor="store_company_register_file" className='btn-basic-small'>수정</label>
                                <button onClick={()=>imgDelete('company')} className='btn-delete-small'>삭제</button>
                            </div>
                            <div>
                                <input type="file" name="store_contract_copy_file" id="store_contract_copy_file" accept="image/*" onChange={inputChange}/>
                                <input type="text" value={
                                    inputs?.file2 ? inputs?.file2.split('/').at(-1) :
                                    (inputs?.store_contract_copy_file?.name ?? '')
                                    } readOnly/>
                                <label htmlFor="store_contract_copy_file" className='btn-basic-small'>수정</label>
                                <button onClick={()=>imgDelete('contract')} className='btn-delete-small'>삭제</button>
                            </div>
                        </li>
                    </ul>
                </div>

                <b>관련 메모</b>
                <textarea name='memo' value={inputs?.memo ?? ''} onChange={inputChange}></textarea>
                <div className='btnArea'>
                    <button className='btn-basic' onClick={onSubmit} disabled={!isSubmit}>수정완료</button>
                </div>
                <button className='btn-delete-small' onClick={storeDelete}>지점삭제</button>
            </div>
            {popup && 
                <Popup type="message" popup={popup} setPopup={setPopup} popupLink={popupLink} absolute/>
            }
        </>
    );
}

