import React, { useRef } from 'react';

export default function ChatImgShow({ imgShowLink, setImgShowLink }) {
    const imgRef = useRef();
    const popupClose = () =>{
        setImgShowLink('');
    }
    return (
        <div className='popup3' onClick={popupClose}>
            <img src={imgShowLink} alt="이미지 크게 보기" ref={imgRef} onClick={(e)=>e.stopPropagation()}/>
        </div>
    );
}

