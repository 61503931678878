import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import NotFound from './pages/NotFound';
import Index from './pages/Index';
// 인트로
// import Intro from './pages/Intro';
// 로그인
import Login from './pages/Login';
import Root from './pages/Root';
// 메인 페이지
import Main from './pages/main/Main';
import SignUp from './pages/main/SignUp';
// 파트너센터
import CenterMain from './pages/center/Main';
import Chat from './pages/center/talk/Chat';
import Comment from './pages/center/talk/Comment';
import Brand from './pages/center/management/Brand';
import Store from './pages/center/management/Store';
import StoreCreate from './pages/center/management/StoreCreate';
import StoreDetail from './pages/center/management/StoreDetail';
import StoreUpdate from './pages/center/management/StoreUpdate';
import Marketing from './pages/center/Marketing';
import PageView from './pages/center/statistics/PageView';
import Interest from './pages/center/statistics/Interest';
import Ranking from './pages/center/statistics/Ranking';
import Charge from './pages/center/point/Charge';
import ChargecHistory from './pages/center/point/ChargecHistory';
import UsageHistory from './pages/center/point/UsageHistory';
import MyPage from './pages/center/myPage/MyPage';
import InfoChange from './pages/center/myPage/InfoChange';
import ServiceInfo from './pages/center/myPage/ServiceInfo';
import ContactUs from './pages/center/myPage/ContactUs';
import Announcement from './pages/center/myPage/Announcement';

import { MyContext } from './context/MyContext';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Index /> ,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Index/> }
    ]
  },
  {
    path: '/login',
    element: <Login />,
    children: [
      { index: true, element: <Login/> }
    ]
  },
  {
    path: '/main',
    element: <Root />,
    children: [
      { index: true, element: <Main/> },
      { path: 'signUp', element: <SignUp/> }
    ]
  },
  {
    path: '/center',
    element: <Root />,
    children: [
      { index: true, element: <CenterMain /> },
      { path: 'talk/chat', element: <Chat /> },
      { path: 'talk/comment', element: <Comment /> },
      { path: 'management/brand', element: <Brand /> },
      { path: 'management/store', element: <Store /> },
      { path: 'management/storeCreate', element: <StoreCreate /> },
      { path: 'management/storeDetail/:id', element: <StoreDetail /> },
      { path: 'management/storeUpdate/:id', element: <StoreUpdate /> },
      { path: 'marketing', element: <Marketing /> },
      { path: 'statistics/pageView', element: <PageView /> },
      // { path: 'statistics/page', element: <Page /> },
      { path: 'statistics/interest', element: <Interest /> },
      { path: 'statistics/ranking', element: <Ranking /> },
      { path: 'point/charge', element: <Charge /> },
      { path: 'point/chargecHistory', element: <ChargecHistory /> },
      { path: 'point/usageHistory', element: <UsageHistory /> },
      { path: 'my', element: <MyPage /> },
      { path: 'my/infoChange', element: <InfoChange /> },
      { path: 'my/contactUs', element: <ContactUs /> },
      { path: 'my/serviceInfo', element: <ServiceInfo /> },
      { path: 'my/announcement', element: <Announcement /> },
    ]
  }
])

function App() {
  const [responsive , setResponsive] = useState();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false)

  // const [scrollPosition , setScrollPosition] = useState(0)
 
  useEffect(()=>{
    if(sessionStorage.getItem('token')){setToken(sessionStorage.getItem('token'))}
    else if(localStorage.getItem('token')){setToken(localStorage.getItem('token'))}
    else{setToken('')}
  },[])
  useEffect(()=>{
    resizeEvent();
    windowHeight()
    window.addEventListener('resize',()=>{
      resizeEvent();
      windowHeight()
    })
    
    window.addEventListener('scroll',()=>{
      // setScrollPosition(window.scrollY)
    })
  },[responsive])
  useEffect(()=>{
    if(token === undefined || token === null){
      return
    }
    setLoading(true)
  },[token])

  const resizeEvent = () =>{
    setResponsive(window.innerWidth > 900)
  }

  function windowHeight(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  return (
    <MyContext.Provider value={{'responsive': responsive,'token':token, loading}}>
      <RouterProvider path="/" router={router} />
    </MyContext.Provider>
  );
}

export default App;
