import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Menu from './Menu';

export default function BrandInfo({info, setInfo, setPopup}) {
    const [inputs, setInputs] = useState({...info,'menu_list': info.menu_list.length ? info.menu_list.map((data)=> ({...data})) : [{}]});
    const [videoTab, setVideoTab] = useState('')
    const [editor, setEditor] = useState(info.brand_detail_info || ' ');
    const [isSubmit, setIsSubmit] = useState();

    useEffect(()=>{
        if(!inputs){return}
        setIsSubmit(Object.entries(info).some(([key, value]) => {
            if (Array.isArray(value)) {
                const prev = Object.keys(value).length >= Object.keys(inputs[key]).length ? value : inputs[key]
                const next = Object.keys(value).length < Object.keys(inputs[key]).length ? value : inputs[key]
                let result;
                prev.forEach((array, idx)=>{
                    if(result) return;
                    Object.entries(array).some(([key2, value2])=>{
                        result = next[idx] ? value2 !== next[idx][key2] : true
                        return result
                    })
                })
                return result;
            }else{
                return value !== inputs[key]
            }
        }))
    },[inputs, setIsSubmit, info])

    const menuAdd = () =>{
        setInputs((input) => {
            let copyData = {...input};
            if(copyData.menu_list.length < 9){
                copyData.menu_list.push({});
            }
            return copyData;
        })
    }

    const menuRemove = (idx) =>{
        setInputs((input) => {
            let copyData = {...input};
            if(copyData.menu_list.length > 1){
                copyData.menu_list.splice(idx, 1)
            }
            return copyData;
        })
    }

    const inputChange = (e) =>{
        const {name, value, files} = e.target;
        if(files){
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                setInputs((input)=>({...input, [name]: files[0], [ name.replace('_id','_path')]: reader.result}))
            }
            return
        }

        setInputs((input) => ({...input, [name]: value}))
    }
    
    
    useEffect(()=>{
        setInputs((input) => ({...input, brand_detail_info: editor}))
    },[editor])


    const fileToId = (obj) => {
        const promises = [];
        
        Object.entries(obj).forEach(([key, value]) => {
            if (key.includes('file_id')) {
                const promise = api.image(value)
                    .then((id) => {
                        obj[key] = id;
                    });
                promises.push(promise);
                delete obj[key.replace('_id', '_path')];
            }
            if (Array.isArray(value)) {
                value.forEach((arrayObj) => {
                    promises.push(fileToId(arrayObj));
                });
            }
        });
        
        return Promise.all(promises)
    };

    
    const onSubmit = async (e) =>{
        e.preventDefault();
        let data = {...inputs}
        await fileToId(data)
        api.brandUpdate('brand_info_update', data)
            .then((data)=>{
                if(data.result){
                    setInfo({...inputs,'menu_list': inputs.menu_list.map((data)=> ({...data}))})
                    setPopup(data.error_message)
                }
            })
    }

    return (
        <div>
            <fieldset className='brandInfoArea'>
                <legend>브랜드 정보</legend>
                <div className="boardBox">
                    <ul className='list'>
                        <li className='videoArea'>
                            <b>
                                브랜드 영상<br/>
                                (선택입력사항)
                            </b>
                            <div>
                                <button onClick={()=>setVideoTab('link')}>링크 등록</button>
                                <button onClick={()=>setVideoTab('file')}>파일 업로드</button>
                                <input type="file" id='brand_video_file' name='brand_video_file_id' accept="video/*" onChange={inputChange}/>
                                <div>
                                    {videoTab === 'link' && (
                                        <>
                                            <input type="text" placeholder='영상 입력' name="brand_video_link" value={inputs.brand_video_link_path ?? ''} onChange={inputChange}/>
                                            {/* <button className='btn-basic-small' onClick={videoRegistration}>등록</button> */}
                                        </>
                                    )}
                                    {videoTab === 'file' && (
                                        <>
                                            <input type="text" placeholder='파일' value={inputs.brand_video_file_path ?? ''} readOnly/>
                                            <label htmlFor="brand_video_file" className='btn-basic-small'>등록</label>
                                        </>
                                    )}
                                </div>
                            </div>
                        </li>
                        <li>
                            <b>
                                브랜드 상세 설명<br/>
                                (이미지•텍스트)
                            </b>
                            <div>
                                <ReactQuill theme="snow" value={editor} onChange={setEditor} /> 
                            </div>
                        </li>
                        <li className='menuArea'>
                            <p>
                                대표 메뉴
                                <mark className='blue'>1개 이상 최대 10개</mark>
                                <button onClick={menuAdd}>추가</button>
                            </p>
                            <div>
                                {inputs && 
                                    inputs.menu_list.map((_, i)=><Menu key={i} idx={i} inputs={inputs} setInputs={setInputs} menuRemove={menuRemove}/>)
                                }
                                {/* {inputs &&
                                    menuComponents()
                                    <Menu idx={0} inputs={inputs} setInputs={setInputs}/>
                                } */}
                            </div>
                        </li>
                        <li className='interiorArea'>
                            <b>인테리어 이미지
                                <mark className='blue'>1개 이상 최대 10개</mark>
                            </b>
                            <div>
                                <input type="file" id='brand_interior_file' name='brand_interior_file_id' accept="image/*" onChange={inputChange}/>
                                <label htmlFor="brand_interior_file" className='camera'>
                                    {inputs.brand_interior_file_path ? <img src={inputs.brand_interior_file_path} alt='인테리어 이미지 미리보기'/> : '이미지 추가'}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <input type="submit" value="등록하기" className='btn-basic' disabled={!isSubmit} onClick={onSubmit}/>
            </fieldset>
        </div>
    );
}

