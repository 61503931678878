import React from 'react';
import PopupMessage from './PopupMessage';
import PopupBrand from './PopupBrand';

export default function Popup({popup: {popup, setPopup}, setBrandResult, popupLink}) {
    const type = /^[ㄱ-ㅎ가-힣]+$/.test(popup[0]);
    return (
        <div className='popup_re' onClick={() => setPopup('')}>
            <div className={type ? 'message' : popup} onClick={(e)=>e.stopPropagation()}>
                {type && <PopupMessage popup={popup} setPopup={setPopup} popupLink={popupLink}/>}
                {popup === 'brand' && <PopupBrand setBrandResult={setBrandResult}/>}
            </div>
        </div>
    );
}

