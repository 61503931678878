import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import MyPoint from '../../../components/center/point/MyPoint';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';

export default function UsageHistory() {
    const [history, setHistory] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState();
    const [page, setPage] = useState(1)
    useEffect(()=>{
        api.point('my_use').then((data)=>{
            setHistory(data.list)
            setTotal(data.data.total_count)
        })
    },[page])
    return (
        <>
            <SubTitle>사용내역</SubTitle>
            <MyPoint />
            <div className="boardBox">
                <div className="title">
                    <span>사용일</span>
                    <span>사용 포인트</span>
                    <span>사용처</span>
                </div>
                <ul className="list">
                    {history && history.map((list, idx)=> ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) && (
                        <li key={list.point_id}>
                            <time>{list.reg_date}</time>
                            <p>{list.amount}</p>
                            <p>{list.comment}</p>
                        </li>
                    ))}
                   {/*  <li>
                        <time>2022-12-18</time>
                        <p>10,000P</p>
                        <p>1:1 대화오픈</p>
                    </li> */}
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
}

