import React, { useContext } from 'react';
import { MyContext } from '../../context/MyContext';
// import { useNavigate } from 'react-router-dom';

export default function SubTitle({children}) {
    const {responsive} = useContext(MyContext);
    // const navigate = useNavigate()
    const prevPageMove = () =>{
        responsive || window.history.go(-1);
        // responsive || navigate('/center')
    }
    return (
        <h2 onClick={prevPageMove}>
           {children} 
        </h2>
    );
}

