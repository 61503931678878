import React, { useCallback, useEffect, useState } from 'react';
import * as api from '../../../api/api';
import Number from '../../../components/center/statistics/Number';
import Graph from '../../../components/center/Graph';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';
// import InquiryDate from '../../../components/center/statistics/InquiryDate';

export default function PageView() {
    const [startDate, setStartDate] = useState('2010-01-01');
    // const [currentDate, setCurrentDate] = useState(()=>{
    //     const today = new Date();
    //     const year = today.getFullYear();
    //     const month = today.getMonth() + 1;
    //     const date = today.getDate();
    //     return `${year}-${month < 10 ? 0 : ''}${month}-${date}`
    // });
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const currentDate = `${year}-${month < 10 ? 0 : ''}${month}-${date}`
    const [endDate, setEndDate] = useState(currentDate);
    const [list, setList] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState();

    const apiStat = useCallback(()=>{
        api.stat('bottom_stat_list', {
            'stat_type': 'pageview',
            'search_start_date': startDate,
            'search_end_date': endDate,
            page
        }).then((data)=>{
            console.log(data);
            setTotal(data.data.total_count)
            setList(data.list)
        })
    },[startDate, endDate, page])

    useEffect(()=>{
        apiStat();
    },[page ,apiStat])

   

    const dateChange = (e) =>{
        const {name, value} = e.target;
        if(name === 'start'){
            setStartDate(value)
        }

        if(name === 'end'){
            setEndDate(value)
        }
    }

    return (
        <>
            <SubTitle>페이지 뷰</SubTitle>
            <Number total={true}/>
            <Graph />
            {/* <InquiryDate type="pageview" page={page} setTotal={setTotal} setList={setList}/> */}
            <div className='search-period'>
                <div>
                    <input type="date" name='start' value={startDate} max={endDate} onChange={dateChange}/>
                    <span>~</span>
                    <input type="date" name='end' value={endDate} min={startDate} max={currentDate} onChange={dateChange}/>
                </div>
                <button className='btn-search' onClick={apiStat}>조회</button>
                {/* <button className='btn-excel'>엑셀 다운로드</button> */}
            </div>
            <div className='boardBox'>
                <div className='title'>
                    <span>날짜</span>
                    <span>PV</span>
                    <span>UV</span>
                </div>
                <ul className='list'>
                    {list && list.map((data, idx)=> ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) && (
                        <li key={idx}>
                            <time>{data.stat_date}</time>
                            <p>{data.stat_pv}</p>
                            <p>{data.stat_uv}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
}

