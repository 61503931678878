import React, { useState } from 'react';
import * as api from '../../../api/api';
import * as validation  from '../../../api/validation';
import DaumPostcode from 'react-daum-postcode';
import SubTitle from '../../../components/center/SubTitle';
import Popup from '../../../components/Popup';

export default function StoreCreate() {
    const [openPostcode, setOpenPostcode] = useState()
    const [inputs, setInputs] = useState({state: '1'});
    const [popup, setPopup] = useState('');
    const [popupLink, setPopupLink] = useState('');

    const addressOpen = () => {
        setOpenPostcode(current => !current);
    }
    const selectAddress = (data) =>{
        setOpenPostcode(current => !current);
        let copyData = {...inputs};
        if(copyData.hasOwnProperty('latitude')){
            delete copyData.latitude;
            delete copyData.longitude;
        }
        copyData = {...copyData, 'address': data.address}
        setInputs(copyData)
    }
    const close = () =>{
        setOpenPostcode(false);
    }

    const inputChange = (e) => {
        const {name, value, type, files} = e.target;
        setInputs((input)=> ({...input, [name]: type === 'file' ? files[0] : value}))
    }
    const onSubmit = async () =>{
        let data = {...inputs}
        
        if(!data.store_name){
            setPopup('지점명을 작성해주세요.') 
            return;
        }

        if(!data.owner_name){
            setPopup('가맹점주를 작성해주세요.')
            return;
        }

        if(!data.owner_contact){
            setPopup('가맹점주 연락처를 작성해주세요.') 
            return;
        }

        if(!validation.mobile(data.owner_contact)){
            setPopup('가맹점주 연락처를 확인해주세요.') 
            return;
        }

        if(!data.address){
            setPopup('지점주소를 작성해주세요.') 
            return;
        }

        if(!data.store_contact){
            setPopup('지점 전화번호를 작성해주세요.') 
            return;
        }

        if(!validation.mobile(data.store_contact)){
            setPopup('지점 전화번호를 확인해주세요.') 
            return;
        }

        if(!data.open_date){
            setPopup('오픈일/오픈예정일을 작성해주세요.') 
            return;
        }

        // 주소
        if(!data.hasOwnProperty('latitude')){
            const address = await api.address(data.address)
            data = {...data, ...address};
            data.latitude = Number(data.latitude)
            data.longitude = Number(data.longitude)
        }

        // 사업자 등록증
        if(!data.hasOwnProperty('store_company_register_file_id') && data.hasOwnProperty('store_company_register_file')){
            const companyId = await api.image(inputs.store_company_register_file);
            data.store_company_register_file_id = parseInt(companyId);
            setInputs((input)=> ({...input, 'store_company_register_file_id': parseInt(companyId)}))
        }
        
        if(data.hasOwnProperty('store_company_register_file')){
            delete data.store_company_register_file;
        }

        // 계약서 사본 등록
        if(!data.hasOwnProperty('store_contract_copy_file_id') && data.hasOwnProperty('store_contract_copy_file')){
            const contractId = await api.image(inputs.store_company_register_file);
            data.store_contract_copy_file_id = parseInt(contractId);
            setInputs((input)=> ({...input, 'store_contract_copy_file_id': parseInt(contractId)}))
        }
        
        if(data.hasOwnProperty('store_contract_copy_file')){
            delete data.store_contract_copy_file;
        }

        api.store('insert', data).then((data)=>{
            if(data.result){
                setPopup(data.error_message);
                setPopupLink('/center/management/store')
            }
        })
    }
    return (
        <div onClick={close}>
            <SubTitle>추가 등록</SubTitle>
            <ul className='textList'>
                <li><span>관리 업체 비활성화 시 해당업체는 로그인 후 드라이버 관리 및 등록은 가능하나 배차운영에서 배제됩니다. </span></li>
                <li><span>사업자 등록증과 계약서사본은 필수입력사항이 아니며 등록 후 상세화면 <b>[수정하기]</b>에서 업데이트가 가능합니다.</span></li>
            </ul>
            {popup && (
                    <Popup type="message" popup={popup} setPopup={setPopup} popupLink={popupLink} absolute/>
                )
            }
            
            <div className="boardBox">
                <div className="title">
                    <span>지점명</span>
                    <span>가맹점주</span>
                    <span>가맹점주 연락처</span>
                    <span>진행상태</span>
                </div>
                <ul className="list">
                    <li>
                        <div>
                            <input type="text" name='store_name' onChange={inputChange}/>
                        </div>
                        <div>
                            <input type="text" name='owner_name' onChange={inputChange}/>
                        </div>
                        <div>
                            <input type="text" name='owner_contact' maxLength={11} onChange={inputChange}/>
                        </div>
                        <div>
                            <select name="state" onChange={inputChange}>
                                <option value="1">계약중</option>
                                <option value="2">오픈예정</option>
                                <option value="3">오픈</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="boardBox">
                <div className="title">
                    <span>지점주소</span>
                    <span>지점 전화번호</span>
                    <span>오픈일/오픈예정일</span>
                </div>
                <ul className="list">
                    <li>
                        <div style={{position: 'relative'}} onClick={(e)=>e.stopPropagation()}>
                            <input type="text" name='address' value={inputs.address ?? ''} onClick={addressOpen} onChange={inputChange}/>
                            {openPostcode && 
                                <DaumPostcode style={{position: 'absolute', border: '1px solid black'}}
                                    onComplete={selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                    autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                                    />}
                        </div>
                        <div>
                            <input type="text" name='store_contact' maxLength={11} onChange={inputChange}/>
                        </div>
                        <div>
                            <input type="date" name='open_date' onChange={inputChange}/>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="boardBox">
                <div className="title">
                    <span>사업자 등록증 등록</span>
                    <span>계약서 사본 등록</span>
                </div>
                <ul className="list">
                    <li>
                        <div>
                            <input type="file" name="store_company_register_file" id="store_company_register_file" onChange={inputChange}/>
                            <input type="text" value={inputs?.store_company_register_file?.name ?? ''} readOnly/>
                            <label htmlFor="store_company_register_file" className='btn-basic-small'>첨부</label>
                        </div>
                        <div>
                            <input type="file" name="store_contract_copy_file" id="store_contract_copy_file" onChange={inputChange}/>
                            <input type="text" value={inputs?.store_contract_copy_file?.name ?? ''} readOnly/>
                            <label htmlFor="store_contract_copy_file" className='btn-basic-small'>첨부</label>
                        </div>
                    </li>
                </ul>
            </div>

            <b>관련 메모</b>
            <textarea name='memo' onChange={inputChange}></textarea>
            <div className='btnArea'>
                <button className='btn-basic' onClick={onSubmit}>등록하기</button>
            </div>
        </div>
    );
}

