import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className='contentsSize'>
            <ul>
                <li><Link to="https://api.changtalk.co.kr/policy/service.html">이용약관</Link></li>
                <li><Link to="/">유료서비스 이용약관</Link></li>
                <li className="boldText"><Link to="https://api.changtalk.co.kr/policy/privacy.html">개인정보처리방침</Link></li>
                <li><Link to="/">통신판매업신고</Link></li>
            </ul>
            <div>
                <mark>입점/등록/운영문의</mark>
                <a href="tel:+82025454224">02-545-4224</a>
                <p>평일, 토요일 10:00 - 19:00</p>
            </div>
            <p>
                Copyright©창업TALK. All Rights Reserved
            </p>
        </footer>
    );
}

