import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import MyPoint from '../../../components/center/point/MyPoint';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';

export default function ChargecHistory() {
    const [history, setHistory] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState();
    const [page, setPage] = useState(1)
    useEffect(()=>{
        api.point('my_charge',{page}).then((data)=>{
            setHistory(data.list)
            setTotal(data.data.total_count)
        })
    },[page])

    return (
        <>
            <SubTitle>충전내역</SubTitle>
            <MyPoint />
            <div className="boardBox">
                <div className="title">
                    <span>충전일</span>
                    <span>유료 포인트</span>
                    <span>무료 포인트</span>
                    <span>비고</span>
                </div>
                <ul className="list">
                    {history && history.map((data, idx)=> ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) && (
                        <li key={data.point_id}>
                            <time>{data.reg_date}</time>
                            <p>{data.amount}</p>
                            <p>{data.free_point}</p>
                            <p>{data.comment}</p>
                        </li>
                    ))}
                    {/* <li>
                        <time>2022-12-18</time>
                        <p>10,000P</p>
                        <p>1,000P</p>
                        <p>포인트</p>
                    </li> */}
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
}

