import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PopupMessage({popup, setPopup, popupLink}) {
    const navigate = useNavigate();
    const onConfirm = () =>{
        setPopup('')
        popupLink && navigate(popupLink)
    }
    return (
        <>
            <p>{popup}</p>
            <button onClick={onConfirm}>확인</button>
        </>
    );
}

