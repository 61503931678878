import React, { useEffect, useState } from 'react';
import * as validation from '../../../api/validation';

export default function Menu({idx, inputs, setInputs, menuRemove}) {
    const [menuData, setMenuData] = useState(()=>({...inputs.menu_list[idx], imgUrl: inputs.menu_list[idx].menu_file}));

    useEffect(()=>{
        setMenuData(()=>({...inputs.menu_list[idx], imgUrl: inputs.menu_list[idx].menu_file}))
    },[inputs, idx])

 

    const inputChange = (e) => {
        const {name, value , files, dataset: {format}} = e.target;

        if(format === 'number' && !validation.number(value)) return;

        if(files){
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                setInputs((input) => {
                    let copyData = {...input}
                    copyData.menu_list[idx][name] = files[0];
                    copyData.menu_list[idx][name.replace('_id','_path')] = reader.result;
                    return copyData;
                })
            }
            return;
        }
        setInputs((input) => {
            let copyData = {...input}
            copyData.menu_list[idx][name] = value;
            return copyData;
        })
    }

    return (
        <div>
            <input type="file" id={`menu_file${idx}`} name='menu_file_id' accept="image/*" onChange={inputChange}/>
            <label htmlFor={`menu_file${idx}`} className='camera'>
                {menuData.menu_file_path ? (
                    <>
                        <img src={menuData.menu_file_path} alt='메뉴 이미지'/>
                        <button onClick={()=>menuRemove(idx)}>삭제</button>
                    </>
                    ) : '이미지 추가'}
            </label>
            
            <div>
                <div>
                    <input type="text" name='menu_name' placeholder="메뉴명" value={menuData?.menu_name ?? ''} onChange={inputChange} />
                </div>
                <div>
                    <input type="text" name='menu_price' data-format="number" placeholder="가격" value={menuData?.menu_price ?? ''} onChange={inputChange}/>
                </div>
            </div>
        </div>
    );
}

