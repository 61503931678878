import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import { Link } from 'react-router-dom';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';

export default function Store() {
    const [list, setList] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState();

    useEffect(()=>{
        api.store('select',{page}).then((data)=> {
            setList(data.list)
            setTotal(data.data.total_count);
        })
    },[page])


    return (
        <>
            <SubTitle>지점 관리</SubTitle>
            <ul className='textList'>
                <li><span>가맹 추가 등록이 필요할 시 오른쪽 상단 <b>[추가등록]</b>버튼을 통해 입력하며, 수정이 필요할 시 지점명을 눌러 상세화면에서 <b>[수정]</b>버튼을 눌러주세요.</span></li>
                <li><span>조건 검색이 필요할 시 상태값 필터 및 검색어 적용 후 <b>[검색]</b>버튼을 눌러주세요.</span></li>
            </ul>
            <div className='searchArea'>
                <label htmlFor="">상태</label>
                <select>
                    <option value="">지점명</option>
                    <option value="">연락처</option>
                    <option value="">가맹점주</option>
                </select>
                <label htmlFor="">검색어</label>
                <input type="search" placeholder='지점명, 연락처, 가맹점주'/>
                <button>검색</button>
            </div>
            <div className='checkArea'>
                {/* <select>
                    <option value=""></option>
                </select> */}
                <p>총 <mark>{total}</mark>건의 데이터가 조회되었습니다.</p>
                {/* <button className='btn-excel'>엑셀 다운로드</button> */}
            </div>
            <div className="boardBox">
                <div className="title">
                    <span>NO.</span>
                    <span>지점명</span>
                    <span>연락처</span>
                    <span>가맹점주</span>
                    <span>상태</span>
                    <span>오픈일/예정일</span>
                </div>
                <ul className="list">
                {list && list.map((data, idx)=> ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) && (
                        <li key={data.store_id}>
                            <p>{idx + 1 + ((page - 1) * 50)}</p>
                            <Link to={`/center/management/storeDetail/${data.store_id}`}>{data.store_name}</Link>
                            <p>{data.store_contact}</p>
                            <p>{data.owner_name}</p>
                            <p>{data.state_comment}</p>
                            <p>{data.open_date}</p>
                        </li>
                    ))}
                    {/* <li>
                        <p>37</p>
                        <Link to='/'>창업톡강남</Link>
                        <p>010-1245-8328</p>
                        <p>김길동</p>
                        <p>계약중</p>
                        <p>2020-09-18</p>
                    </li> */}
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            <Link to='/center/management/storeCreate' className='btn-add'>추가 등록</Link>
        </>
    );
}

