import React from 'react';
import MyPage from './MyPage';

export default function Announcement() {
    return (
        <MyPage>
            <h3>공지사항</h3>
            <div className="boardBox">
                <ul className="list">
                    <li className='new'>
                        <details>
                            <summary>
                                <strong>버전 업데이트가 진행될 예정이에요!</strong>
                                <time>2021.12.18 14:08</time>
                            </summary>
                            <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>
                                <strong>가맹사와 함께 하는 이벤트!</strong>
                                <time>2021.12.18 14:08</time>
                            </summary>
                            <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                        </details>
                    </li>
                    <li>
                        <details>
                            <summary>
                                <strong>창업톡이 오픈했습니다!</strong>
                                <time>2021.12.18 14:08</time>
                            </summary>
                            <p>텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.텍스트 영역 입니다.</p>
                        </details>
                    </li>
                </ul>
            </div>
        </MyPage>
    );
}

