import React, { useEffect, useState } from 'react';
import * as api from '../../api/api';
import SubTitle from '../../components/center/SubTitle';

export default function Marketing() {
    const [marketingList, setMarketingList] = useState();
    useEffect(()=>{
        api.marketing('list').then(setMarketingList)
    },[])
    return (
        <>
            <SubTitle>마케팅</SubTitle>
            {marketingList &&
                <ul>
                    {marketingList.map((list)=>(
                        <li key={list.marketing_id} style={{'backgroundImage': `url(${list.marketing_image_file})`}}>
                            <span>바이럴</span>
                            <p>{list.title}</p>
                        </li>
                    ))}
                </ul>
            }
           {/*  <ul>
                <li style={{'backgroundImage': `url(${require('../../images/delete/marketing.png')})`}}>
                    <span>바이럴</span>
                    <p>인플루언서를 우리 매장에 초대하는 방법</p>
                </li>
            </ul> */}
        </>
    );
}