import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import * as validation from '../../../api/validation';

export default function BrandCost({cost, setCost, setPopup}) {
    const [inputs, setInputs] = useState();
    // const [startUpList, setStartUpList] = useState(['보증금', '교육비', '가맹비', '인테리어','기타'])
    const startUpList = ['보증금', '교육비', '가맹비', '인테리어','기타'];
    const companyList = ['로열티','광고/판촉비'];
    const [isSubmit, setIsSubmit] = useState();

    useEffect(()=>{
        let copyData = {...cost, 'company_paiement_money': {...cost.company_paiement_money}}
        setInputs(copyData)
    },[cost])
    
    useEffect(()=>{
        if(!inputs){return}
        setIsSubmit(Object.entries(cost).some(([key, value]) => {
            if(typeof value === 'object'){
                const prev = Object.keys(value).length >= Object.keys(inputs[key]).length ? value : inputs[key]
                const next = Object.keys(value).length < Object.keys(inputs[key]).length ? value : inputs[key]
                return Object.entries(prev).some(([key2, value2])=>{
                    return value2 !== next[key2]
                })
            }else{
                return value !== inputs[key]
            }
        }))
    },[inputs, setIsSubmit, cost])

    const inputChange = (e) => {
        let {name, value} = e.target;
        let copyData = {...inputs}
        value === '' || (value = parseInt(value))

        if(startUpList.includes(name)){
            value === '' ? delete copyData.start_up_money_info[name] : 
                copyData.start_up_money_info[name] = value;
            copyData.start_up_money = 0;
            Object.entries(copyData.start_up_money_info).forEach(([_, value]) =>{
                copyData.start_up_money = value + copyData.start_up_money
            })
        }

        if(companyList.includes(name)){
            value === '' ? delete copyData.company_paiement_money[name] : 
                copyData.company_paiement_money[name] = value;
        }

        if(name === 'standard_store_area'){
            value === '' ? delete copyData.standard_store_area : 
                copyData.standard_store_area = value;
        }

        setInputs(copyData);
    }

    
    const onSubmit = (e) => {
        e.preventDefault();
        let data = {...inputs, 'start_up_money_info' : [] , 'company_paiement_money': []};
        Object.entries(inputs.start_up_money_info).forEach(([name, value]) => {
            data.start_up_money_info.push({name, value})
        })
        Object.entries(inputs.company_paiement_money).forEach(([name, value]) => {
            data.company_paiement_money.push({name, value})
        })
        api.brandUpdate('money_info_update', data).then((data)=>{
            if(data.result){
                let copyData = {...inputs, 'company_paiement_money': {...inputs.company_paiement_money}}
                setCost(copyData)
                setPopup(data.error_message)
            }
        })
    }
    return (
        <form>
            <fieldset className='costArea'>
                <legend>창업비용</legend>
                <div className="boardBox">
                    <ul className='list'>
                        <li>
                            <label htmlFor="">점포면적 기준</label>
                            <div data-unit="m2(제곱미터)">
                                <input type="number" name='standard_store_area' placeholder='숫자만 입력' value={inputs?.standard_store_area ?? ''} onChange={inputChange}/>
                            </div>
                        </li>
                        {inputs && startUpList.map((data, idx)=>
                            <li key={idx}>
                                <label htmlFor="">{data}</label>
                                <div>
                                    <input type="number" name={data} min={0} placeholder='숫자만 입력' value={inputs?.start_up_money_info[data] ?? ''} onChange={inputChange}/>
                                </div>
                            </li>

                        )}
                    </ul>
                </div>
                <mark>총합<span>{inputs && validation.addCommas(inputs.start_up_money)}</span></mark>
                <small>Vat별도</small>
                <div className="boardBox">
                    <ul className='list'>
                        {inputs && companyList.map((data, idx)=>
                            <li key={idx}>
                                <label htmlFor="">{data}</label>
                                <div>
                                    <input type="number" name={data} min={0} placeholder='숫자만 입력' value={inputs.company_paiement_money[data] ?? ''} onChange={inputChange}/>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <input type="submit" value="등록하기" className='btn-basic' disabled={!isSubmit} onClick={onSubmit}/>
            </fieldset>
        </form>
    );
}

