import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import MyPoint from '../../../components/center/point/MyPoint';
import SubTitle from '../../../components/center/SubTitle';
import Popup from '../../../components/Popup';

export default function Charge() {
    const [products, setProducts] = useState();
    const [popup, setPopup] = useState('');
    const [popupLink, setPopupLink] = useState('');
    useEffect(()=>{
        api.point('product').then((data)=>setProducts(data.list))
    },[])
    
    const charge = (conts_id) =>{
        api.point('charge',{conts_id}).then((data)=>{
            setPopup(data.error_message)
            setPopupLink('새로고침')
        })
    }
    return (
        <>
            <SubTitle>충전하기</SubTitle>
            <p>
                1:1 대화를 오픈하려면 포인트 사용이 필수!<br/>
                필요한 포인트 충전으로  원활한 서비스를 이용해보세요 :&#41;
            </p>
            <MyPoint />
            {products && 
                <ul>
                    {products.map((list)=>(
                        <li key={list.conts_id}>
                            <p>
                                <strong>{list.sales_price}P</strong>
                                <b>BONUS</b> <mark>{list.bonus_price}</mark>
                            </p>
                            <button className='btn-basic' onClick={()=>charge(list.conts_id)}>충전하기</button>
                        </li>
                    ))}
                </ul>
            }
            <small>* 보너스 포인트는 3개월 이내 미소진시 소멸됩니다.</small>
            {popup && 
                <Popup type="message" popup={popup} setPopup={setPopup} popupLink={popupLink} absolute/>
            }
        </>
    );
}

