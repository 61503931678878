import React, { useCallback, useEffect, useState } from 'react';
import * as api from '../../../api/api';
import Number from '../../../components/center/statistics/Number';
import ComponentRanking from '../../../components/center/Ranking';
import SubTitle from '../../../components/center/SubTitle';
import Pager from '../../../components/center/Pager';

export default function Ranking() {
    const [startDate, setStartDate] = useState('2010-01-01');
    const [endDate, setEndDate] = useState(()=>{
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        return `${year}-${month < 10 ? 0 : ''}${month}-${date}`
    });
    const [topData, setTopData] = useState();
    const [bottomData, setBottomData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState();
    useEffect(()=>{
        api.stat('ranking_top_info').then((data)=>{
            setTopData(data.data)
        })
    },[])

    const apiStat = useCallback(()=>{
        api.stat('ranking_bottom_stat_list' ,{
            'search_start_date': startDate,
            'search_end_date': endDate,
            page,
            "limit":50
        }).then((data)=>{
            setTotal(data.data.total_count)
            setBottomData(data.list)
        })
    },[startDate, endDate, page])
    
    useEffect(()=>{
        apiStat();
    },[page, apiStat])

    

    const dateChange = (e) =>{
        const {name, value} = e.target;
        if(name === 'start'){
            setStartDate(value)
        }

        if(name === 'end'){
            setEndDate(value)
        }
    }
    return (
        <>
            <SubTitle>랭킹통계</SubTitle>
            {topData && 
                <>
                    <Number rankingData={{
                        interest: topData.interest, 
                        store: topData.store, 
                        talk: topData.talk,
                        interestRank: topData.my_ranking1?.rank,
                        storeRank: topData.my_ranking2?.rank,
                        talkRank: topData.my_ranking3?.rank,
                    }}/>
                    <ComponentRanking 
                        my_ranking1={topData.my_ranking1}
                        my_ranking2={topData.my_ranking2}
                        my_ranking3={topData.my_ranking3}
                        ranking1 = {topData.ranking1}
                        ranking2 = {topData.ranking2}
                        ranking3 = {topData.ranking3}
                    />
                </>
            }
            <div className='search-period'>
                <div>
                    <input type="date" name='start' value={startDate} onChange={dateChange}/>
                    <span>~</span>
                    <input type="date" name='end' value={endDate} onChange={dateChange}/>
                </div>
                <button className='btn-search' onClick={apiStat}>조회</button>
                {/* <button className='btn-excel'>엑셀 다운로드</button> */}
            </div>
            <div className='boardBox'>
                <div className='title'>
                    <span>날짜</span>
                    <span>관심도</span>
                    <span>관심도 순위</span>
                    <span>창업톡 수</span>
                    <span>창업톡 수 순위</span>
                    <span>점포 수</span>
                    <span>점포 수 순위</span>
                </div>
                <ul className='list'>
                    {bottomData && 
                        bottomData.map((data, idx) => ((currentPage - ((page - 1) * 5)) * 10 <= idx && ((currentPage - ((page - 1) * 5)) * 10 + 10) > idx) &&
                            <li key={idx}>
                                <time>{data.stat_date}</time>
                                <p>{data.value1}</p>
                                <p>{data.ranking1}</p>
                                <p>{data.value2}</p>
                                <p>{data.ranking2}</p>
                                <p>{data.value3}</p>
                                <p>{data.ranking3}</p>
                            </li>
                        )
                    }
                </ul>
            </div>
            <Pager total={total} page={page} setPage={setPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
}

