import React, { useEffect, useState } from 'react';
import * as api from '../../api/api';
// import * as validation from '../../api/validation';
import CenterNav from './CenterNav';
import { Link, useNavigate } from 'react-router-dom';
import MyPoint from '../center/point/MyPoint';

export default function CenterHeader() {
    const navigate = useNavigate();
    const [token, setToken] = useState();
    useEffect(()=>{
        api.tokenInit()
        if(localStorage.getItem('token')){setToken(localStorage.getItem('token'))}
        else if(api.getCookieValue('token')){setToken(api.getCookieValue('token'))}
        else{setToken('')}
    },[])
    useEffect(()=>{
        if(token === undefined || token === null){
            return
        }
        !token && navigate('/login', { replace: true })
    },[token, navigate])

    const [isMenu, setIsMenu] = useState(false)
    useEffect(()=>{
        // setIsMenu(false)
        return() => {
        }
    },[isMenu])
    return (
        <header>
            <h1><Link to={'/main'}><img src={require('../../images/common/logo.png')} alt="창업톡" /> 파트너센터</Link></h1>
            <button onClick={()=>setIsMenu(true)}>메뉴 열기</button>
            <div className={isMenu ? 'active' : ''}>
                <MyPoint />
                <Link to="/center/my" onClick={()=>setIsMenu(false)}>
                    <img src={require('../../images/delete/profile.png')} alt="프로필 이미지" />
                    <p>
                        <b>{localStorage.getItem('brand_name')}</b>
                        {localStorage.getItem('name') !== 'null' ? `(${localStorage.getItem('name')})` :''}
                    </p>
                </Link>
                <CenterNav setIsMenu={setIsMenu}/>
                <button onClick={()=>setIsMenu(false)}>메뉴 닫기</button>
            </div>
        </header>
    );
}

