import React from 'react';

export default function Ranking({my_ranking1, my_ranking2, my_ranking3, ranking1, ranking2, ranking3}) {
    return (
        <div className='rankingBox'>
            <ol title='관심도'>
                {ranking1.slice(0, (my_ranking1?.rank > 6 ? 5 : 6)).map((data, idx)=>
                    <li className={data.my_yn === 'y' ? 'active' : ''} key={data.brand_id}>
                        <b>{data.rank}</b>
                        {data.brand_name}
                        <b>{data.total_stat}</b>
                    </li>
                )}
                {my_ranking1?.rank > 6 &&
                    <li className='active'>
                        <b>{my_ranking1?.rank}</b>
                        {my_ranking1?.brand_name}
                        <b>{my_ranking1?.total_stat}</b>
                    </li>
                }
            </ol>
            <ol title='창업톡 수'>
                {ranking2.slice(0, (my_ranking2?.rank > 6 ? 5 : 6)).map((data, idx)=>
                    <li className={data.my_yn === 'y' ? 'active' : ''} key={data.r_user_id}>
                        <b>{data.rank}{data.brand_id}</b>
                        {data.brand_name}
                        <b>{data.total_stat}</b>
                    </li>
                )}
                {my_ranking2?.rank > 6 &&
                    <li className='active'>
                        <b>{my_ranking2?.rank}</b>
                        {my_ranking2?.brand_name}
                        <b>{my_ranking2?.total_stat}</b>
                    </li>
                }
            </ol>
            <ol title='점포 수'>
                {ranking3.slice(0 , (my_ranking3?.rank > 6 ? 5 : 6)).map((data, idx)=>
                    <li className={data.my_yn === 'y' ? 'active' : ''} key={data.brand_id}>
                        <b>{data.rank}</b>
                        {data.brand_name}
                        <b>{data.total_stat}</b>
                    </li>
                )}
                {my_ranking3?.rank > 6 &&
                    <li className='active'>
                        <b>{my_ranking3?.rank}</b>
                        {my_ranking3?.brand_name}
                        <b>{my_ranking3?.total_stat}</b>
                    </li>
                }
            </ol>
        </div>
    );
}

