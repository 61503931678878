import React, { useEffect, useState } from 'react';
import * as api from '../../../api/api';
import * as validation from '../../../api/validation';
import { useNavigate } from 'react-router-dom';

export default function MyPoint() {
    const navigate = useNavigate();
    const [point, setPoint] = useState()
    useEffect(()=>{
        api.point('my_point').then((data)=>{
            if(data.result){
                setPoint(validation.addCommas(data.data.total_point))
            } else {
                localStorage.removeItem('token')
                document.cookie = `token=`
                navigate('/login')
            }
        })
    },[navigate])
    
    return (
        <div className='myPoint'>
            <p>나의 사용가능 포인트</p>
            <mark>{point}</mark>
        </div>
    );
}

