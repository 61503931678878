import React, { useEffect } from 'react';

export default function Pager({total, currentPage, setCurrentPage, page, setPage}) {
    total = parseInt((total - 1) / 10 + 1)
    
    useEffect(()=>{
        parseInt(currentPage / 5) + 1 !== page && setPage(parseInt(currentPage / 5) + 1)
    },[currentPage,page,setPage])
    if(total <= 1){return}
    const pageChange = (type) =>{
        type === 'prev' && currentPage > 0 && setCurrentPage(currentPage - 1)
        type === 'next' && currentPage < total - 1 && setCurrentPage(currentPage + 1)
        typeof(type) === 'number' && setCurrentPage(type - 1)
    }

    

    return (
        <div className='pagerBox'>
            <button onClick={()=>pageChange('prev')}>이전</button>
            <ol>
                { Array.from({ length: total < 6 ? total : 5 }, (_, idx) => (
                    total > (idx + ((page - 1) * 5)) &&
                    <li key={idx}>
                        <button className={currentPage === (idx + ((page - 1) * 5)) ? 'active' : ''} onClick={()=>pageChange(idx + ((page - 1) * 5))}>
                            {++idx + ((page - 1) * 5)}
                        </button>
                    </li>
                ))}
            </ol>
            <button onClick={()=>pageChange('next')}>다음</button>
        </div>
    );
}

