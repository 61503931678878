const apiURL = process.env.REACT_APP_FIREBASE_API_URL;
// let token = sessionStorage.getItem('token') || (localStorage.getItem('token') || '')
let token;
export function tokenInit(){
    token = getCookieValue('token') || localStorage.getItem('token');
}

tokenInit();

export function getCookieValue(cookieName) {
    const cookies = document.cookie;
    const cookieArray = cookies.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i];
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith(cookieName + '=')) {
        return trimmedCookie.substring(cookieName.length + 1);
      }
    }
    return null;
  }

// 로그인
export function login(data, remember){
    data = JSON.stringify(data);
    return fetch(`${apiURL}login`,{
        method : 'POST',
        body: data
    }).then((res)=>res.json()).then((data)=>{
        if(data.result){
            if(remember){
                localStorage.setItem('token' , data.data.token)
                document.cookie = `token=${data.data.token}`
            } else {
                document.cookie = `token=${data.data.token}`
            }
            tokenInit();
        }
        return data;
    })
}

export function signUp(data){
    data = JSON.stringify(data);
    return fetch(`${apiURL}join`,{
        method : 'POST',
        body: data
    }).then((res)=>res.json())
}

// 회원가입 - 핸드폰 인증 번호 보내기
export function mobile(data){
    console.log(data);
    data = JSON.stringify(data);
    return fetch(`${apiURL}mobile`,{
        method : 'POST',
        body: data
    }).then((res)=>res.json())
}


// 회원가입 - 브랜드 검색
export function brandSearch(data){
    data = {'fucn': 'search', ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}brand`,{
        method : 'POST',
        body: data
    }).then((res)=>res.json()).then((data)=> {
        return data.list
    })
}


// 이미지
export function image(file){
    const data = new FormData();
    data.append('file', file);
    return fetch(`${apiURL}image`,{
        method : 'POST',
        body: data,
        headers: {}
    }).then((res)=>res.json()).then((data)=>data.data.attach_file_id)
}


// 대시보드
export function dashboard(){
    let data = {token};
    data = JSON.stringify(data);
    return fetch(`${apiURL}dashboard`,{
        method : 'POST',
        body: data,
        headers: {}
    }).then((res)=>res.json()).then((data)=>data.data)
}


// 창업톡 
// 창업톡 - 채팅
export function chat(fucn, data){
    data = {token, fucn, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}chat`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}


// 창업톡 - 채팅 - 메세지
export function message(fucn, data){
    data = {token, fucn, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}message`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}

// 창업톡 - 문구 관리
export function comment(type, data){
    data = {token, type, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}comment`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}



// 마케팅
export function marketing(fucn){
    let data = {fucn};
    data = JSON.stringify(data);
    return fetch(`${apiURL}marketing`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data.list
    })
}


// 포인트
export async function point(type , data){
    data = {token, type, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}point`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}


// 브랜드
export function brand(fucn , data){
    data = {token, fucn, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}brand`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        // console.log(fucn, data);
        return data
    })
}


// 브랜드 - 업데이트
export function brandUpdate(fucn, data){
    data = {token, fucn, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}brand`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}



// 지점 관리
export function store(type, data){
    data = {token, type, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}store`,{
        method : 'POST',
        body: data
    })
    .then((res)=> res.json())
    .then((data)=> {
        return data
    })
}

// 주소 검색
export function address(data){
    data = {token,type: 'coordinate' ,'address': data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}address`,{
        method : 'POST',
        body: data
    })
    .then((res)=> {
        return res.json()
    })
    .then((data)=> {
        return data.data
    })
}

// 통계
export function stat(fucn, data){
    data = {token, fucn, ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}stat`,{
        method : 'POST',
        body: data
    })
    .then((res)=> {
        return res.json()
    })
    .then((data)=> {
        return data
    })
}


// 마이 페이지
// 마이 페이지 - 회원 정보 수정
export function profile(data){
    data = {token, fucn: 'update', ...data}
    data = JSON.stringify(data);
    return fetch(`${apiURL}profile`,{
        method : 'POST',
        body: data
    })
    .then((res)=> {
        return res.json()
    })
    .then((data)=> {
        return data
    })
}
